import React from 'react';
import tw, { styled } from 'twin.macro';

interface ComponentProps {
  sm?: boolean;
  xs?: boolean;
  light?: boolean;
}

const LoaderSvg = styled.svg<ComponentProps>`
  ${tw`animate-spin`}
  ${(props) => !props.sm && !props.xs && tw`w-10 h-10`}
  ${(props) => props.sm && tw`w-5 h-5`}
  ${(props) => props.xs && tw`w-4 h-4`}
  ${(props) => (props.light ? tw`text-white` : tw`text-blue-900`)}
`;

function CommonAnimatedSpinner({ sm, xs, light }: ComponentProps) {
  return (
    <LoaderSvg sm={sm} xs={xs} light={light} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </LoaderSvg>
  );
}

CommonAnimatedSpinner.defaultProps = {
  sm: false,
  xs: false,
  light: false,
};

export default CommonAnimatedSpinner;
