import ELTA_LOGO from './images/logo.svg';
import THUMBTACK from './images/icons/thumbtack.svg';

const IMAGES = {
  LOGOTYPES: {
    PRIMARY: ELTA_LOGO,
  },
  ICONS: {
    THUMBTACK,
  },
};

export default IMAGES;
