import { CalendarIcon, DocumentIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';

import CommonButtonDefault from '../../../components/common/buttons/default';
import { useCompany } from '../../../hooks/use-company';
import InvoicesForm from './invoices-form';
import { strings } from '../../../localization/strings';
import AlertPrimary from '../../../components/common/alerts/alert-primary';

function InvoicesBlock() {
  const { companyDetails } = useCompany();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const onUpdatePayments = () => {
    setShowForm(!showForm);
  };

  const onHideForm = () => {
    setShowForm(false);
  };

  const onSuccess = () => {
    setIsSubmitSuccessful(true);
    setTimeout(() => {
      setIsSubmitSuccessful(false);
    }, 1500);
  };

  return (
    <div className="bg-white sm:rounded-lg ring-1 ring-gray-900/5">
      <dt className="flex justify-between p-4 font-medium rounded-lg leading-6 text-gray-900 bg-gray-50">
        Mokėjimo sąlygos{' '}
        <CommonButtonDefault editLink onClick={() => onUpdatePayments()}>
          {!showForm ? 'Keisti' : 'Uždaryti'}
        </CommonButtonDefault>
      </dt>
      {isSubmitSuccessful && (
        <div className="mb-2 px-2">
          <AlertPrimary size="xs" type="success" text="Įmonės informacija sėkmingai atnaujinta" />
        </div>
      )}
      {!showForm && (
        <dl className="flex flex-wrap text-sm">
          <div className="flex items-center w-full gap-x-4 border-t border-gray-900/5 p-4">
            <dt className="flex items-center">
              <CurrencyDollarIcon className="h-6 w-5 text-gray-400 mr-2" aria-hidden="true" />
              <span className="text-sm font-medium text-gray-900">Mokėjimo suma</span>
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {companyDetails.payment_amount ? `€${companyDetails.payment_amount}` : '-'}
            </dd>
          </div>

          <div className="flex items-center w-full gap-x-4 border-t border-gray-900/5 p-4">
            <dt className="flex items-center">
              <CalendarIcon className="h-6 w-5 text-gray-400 mr-2" aria-hidden="true" />
              <span className="text-sm font-medium text-gray-900">Periodiškumas</span>
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {strings.payments.periodicityOptions[companyDetails?.payment_periodicity] || '-'}

              {companyDetails.payment_periodicity === 'individual' && companyDetails.payment_dates && (
                <span className="text-gray-500">
                  {companyDetails.payment_dates.map((date, index) => (
                    <div key={`date-${index + 1}`}>{date}</div>
                  ))}
                </span>
              )}
            </dd>
          </div>
          <div className="flex items-center w-full gap-x-4 border-t border-gray-900/5 p-4">
            <dt className="flex items-center">
              <DocumentIcon className="h-6 w-5 text-gray-400 mr-2" aria-hidden="true" />
              <span className="text-sm font-medium text-gray-900">Sąskaitas pateikti</span>
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {!!companyDetails.invoice_email && <div>{strings.payments.invoiceBy.invoice_email}</div>}
              {!!companyDetails.invoice_einvoice && <div>{strings.payments.invoiceBy.invoice_einvoice}</div>}
            </dd>
          </div>
        </dl>
      )}
      {showForm && <InvoicesForm onCancel={onHideForm} onSuccess={onSuccess} />}
    </div>
  );
}

export default InvoicesBlock;
