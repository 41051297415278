import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import CommonButtonDefault from '../../../components/common/buttons/default';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { useCompany } from '../../../hooks/use-company';

function UsersTab() {
  const { companyDetails: company } = useCompany();
  const [searchParams, setSearchParams] = useSearchParams();
  const [success, setSuccess] = useState<boolean>(searchParams.get('success') === '1' || false);

  if (success) {
    setTimeout(() => {
      setSuccess(false);
      setSearchParams({ success: '' });
    }, 5000);
  }

  return (
    <>
      {success && (
        <div className="mb-4">
          <AlertPrimary size="xs" type="success" text="Vartotojo informacija sėkmingai išsaugota" />
        </div>
      )}

      <header className="mb-4">
        <div className="flex space-x-3 justify-between">
          <div className="ml-auto">
            <Link to={`/users/create/${company.id}`}>
              <CommonButtonDefault type="button" primary>
                Sukurti
              </CommonButtonDefault>
            </Link>
          </div>
        </div>
      </header>

      {!company.has_subscription && (
        <div className="mb-4">
          <AlertPrimary
            size="xs"
            type="warning"
            text="Įmonė neturi aktyvių paslaugų, todėl vartotojai negalės prisijungti"
          />
        </div>
      )}

      {company.users && company.users.length > 0 && (
        <div className="bg-white w-full sm:rounded-lg overflow-hidden ring-1 ring-gray-900/5">
          <ul className="divide-y divide-gray-100">
            {company?.users?.map((user) => (
              <li key={user.id} className="flex items-center justify-between gap-x-6 py-3 px-4">
                <div className="min-w-0">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">{user.username}</p>
                    <p
                      className={classNames(
                        user.is_active
                          ? 'text-green-700 bg-green-50 ring-green-600/20'
                          : 'text-red-700 bg-red-50 ring-red-600/10',
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                      )}
                    >
                      {user.is_active ? 'Aktyvus' : 'Neaktyvus'}
                    </p>
                  </div>
                  <div className="text-sm text-gray-600">{user.name}</div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                  <Link to={`/users/edit/${user.id}`} className="text-indigo-600 hover:text-indigo-900">
                    Keisti
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default UsersTab;
