import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import React, { useState } from 'react';

import CommonButtonDefault from '../../../components/common/buttons/default';
import { useAuth } from '../../../hooks/use-auth';
import { useCompany } from '../../../hooks/use-company';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import { getUnixTimestamp, isValidSubscriptionDates } from '../../../services/formatter/date';
import * as companiesService from '../../../services/api/companies';
import StyledInput from '../../../components/common/form/styled-input';
import StyledSwitch from '../../../components/common/form/styled-switch';
import LabelWithError from '../../../components/common/form/label-with-error';
import Tooltip from '../../../components/common/tooltip';

type Inputs = {
  photos_active: number;
  photos_from: Date;
  photos_to: Date;
  photos_archive: number;
  photos_monthly_limit: number;
  photos_download_from: Date | undefined;
};

function ServicesPhotosForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const { token } = useAuth();
  const { updateCompany, companyDetails } = useCompany();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      photos_active: companyDetails.photos_active,
      photos_from: companyDetails.photos_from ? new Date(companyDetails.photos_from * 1000) : undefined,
      photos_to: companyDetails.photos_to ? new Date(companyDetails.photos_to * 1000) : undefined,
      photos_archive: companyDetails.photos_archive,
      photos_monthly_limit: companyDetails.photos_monthly_limit,
      photos_download_from: companyDetails.photos_download_from
        ? new Date(companyDetails.photos_download_from * 1000)
        : undefined,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (companyDetails?.id) {
        setIsLoading(true);

        const formData = {
          ...data,
          photos_active: data.photos_active ? 1 : 0,
          photos_archive: data.photos_archive ? 1 : 0,
          photos_from: data.photos_from && data.photos_active ? getUnixTimestamp(data.photos_from) : null,
          photos_to: data.photos_to && data.photos_active ? getUnixTimestamp(data.photos_to) : null,
          photos_download_from: data.photos_download_from ? getUnixTimestamp(data.photos_download_from) : 0,
        };

        const res = await companiesService.update(token, companyDetails?.id, formData);

        if (res) {
          setIsLoading(false);
          setIsSubmitSuccessful(true);

          updateCompany(formData);

          setTimeout(() => {
            setIsSubmitSuccessful(false);
          }, 2000);
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  return (
    <>
      {error && (
        <div className="max-w-7xl mx-auto my-5">
          <AlertPrimary type="danger" text={error} />
        </div>
      )}
      {isSubmitSuccessful && (
        <div className="mb-2 mt-4">
          <AlertPrimary size="xs" type="success" text="Sėkmingai atnaujinta" />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white sm:rounded-lg ring-1 ring-gray-900/5 mt-8">
          <div className="p-4 font-medium leading-6 bg-gray-50 text-gray-900 flex flex-row sm:rounded-t-lg">
            <h3>Nuotraukų prenumerata (Fotobankas)</h3>
            <div className="ml-auto">
              <CommonButtonDefault type="submit" isLoading={isLoading} xs primary>
                Išsaugoti
              </CommonButtonDefault>
            </div>
            <div className="ml-4">
              <Tooltip
                disabled={!isValidSubscriptionDates(watch('photos_from'), watch('photos_to'))}
                title={
                  <StyledSwitch
                    name="photos_active"
                    control={control}
                    disabled={isValidSubscriptionDates(watch('photos_from'), watch('photos_to'))}
                  />
                }
              >
                Norėdami išjungti paslaugą, ištrinkite jos galiojimo laiką
              </Tooltip>
            </div>
          </div>
          {watch('photos_active') === 1 && (
            <div>
              <div className="grid grid-cols-3 gap-x-4 p-4">
                <div className="col-span-1">
                  <LabelWithError title="Nuotraukų prenumeratos pradžia" error={errors.photos_from?.message}>
                    <Controller
                      control={control}
                      name="photos_from"
                      render={({ field }) => (
                        <DatePicker
                          // monthsShown={2}
                          showYearDropdown
                          dateFormat="yyyy-MM-dd"
                          isClearable
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          selected={field.value ? new Date(field.value) : undefined}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          disabledKeyboardNavigation
                        />
                      )}
                    />
                  </LabelWithError>
                </div>
                <div className="col-span-1">
                  <LabelWithError title="Nuotraukų prenumeratos pabaiga" error={errors.photos_to?.message}>
                    <Controller
                      control={control}
                      name="photos_to"
                      render={({ field }) => (
                        <DatePicker
                          // monthsShown={2}
                          showYearDropdown
                          dateFormat="yyyy-MM-dd"
                          isClearable
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          selected={field.value ? new Date(field.value) : undefined}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          disabledKeyboardNavigation
                        />
                      )}
                    />
                  </LabelWithError>
                </div>
                <div className="col-span-1">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    Nuotraukų kiekis (per mėnesį)
                  </label>
                  <div className="mt-1">
                    <StyledInput {...register('photos_monthly_limit')} />
                  </div>
                  <p className="mt-1 text-sm text-gray-500" id="email-description">
                    {companyDetails.photos_monthly_limit > 0 && (
                      <>
                        Nuotraukų likutis (šį mėnesį):{' '}
                        {companyDetails.photos_monthly_limit - companyDetails.photos_downloaded_this_month}
                        <br />
                      </>
                    )}
                    Jeigu reikia neriboti - palikite tuščia lauką
                  </p>
                </div>
                <div className="col-span-1">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    Leisti siųstis senesnes nei nuo 2010 m.
                  </label>
                  <div className="mt-1">
                    <StyledSwitch name="photos_archive" control={control} />
                  </div>
                </div>
                {watch('photos_archive') === 1 && (
                  <div className="col-span-1">
                    <LabelWithError title="Leisti siųstis nuo:" error={errors.photos_download_from?.message}>
                      <Controller
                        control={control}
                        name="photos_download_from"
                        render={({ field }) => (
                          <DatePicker
                            showYearDropdown
                            dateFormat="yyyy-MM-dd"
                            isClearable
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            selected={field.value ? new Date(field.value) : undefined}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            disabledKeyboardNavigation
                          />
                        )}
                      />
                    </LabelWithError>
                    <p className="mt-1 text-sm text-gray-500" id="email-description">
                      Jeigu reikia leisti siųstis neribojant laiko - palikite tuščią lauką
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
}

export default ServicesPhotosForm;
