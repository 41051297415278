import React from 'react';
import classNames from 'classnames';

import IMAGES from '../../assets/images';
import { strings } from '../../localization/strings';
import { parameters } from '../../constants/parameters';

function SidebarComponent() {
  /*
    // Oranžinė
    // Priskirta Man
      // čia kiekvienas viduje turi dar kategorijas
      // Naujienos
      // Nuotraukos
      // Pranešimai spaudai

    // Mėlyna
    // Ruošiamos naujienos
      // kategorijos viduje
    // Užsienio agentūrų naujienos
      // AFP
      // DPA
      // UKRINFORM
      // PAP (EN)
      // PAP (LT)
    // Atidėtas publikavimas
      // kategorijos viduje
    // Publikuotos naujienos
      // kategorijos viduje

    // Žalia
    // Ruošiamos nuotraukos
      // kategorijos viduje
    // Užsienio agentūrų nuotraukos
      // EPA?
    // Publikuotos nuotraukos
      // kategorijos viduje

    // Geltona
    // Ruošiami pranešimai spaudai
      // kategorijos viduje
    // Atidėtas publikavimas pranešimai spaudai
      // kategorijos viduje
    // Publikuoti pranešimai spaudai
      // kategorijos viduje

    // Balta
    // Numatomi įvykiai
    // Konferencijos
      // Ruošiamos konferencijos
      // Publikuotos konferencijos
      // Atidėtas publikavimas
    // Šiukšliadėžė
      // Naujienos
        // kategorijos viduje
      // Nuotraukos
        // kategorijos viduje
    // TVS
      // Baneriai
      // Pagrindinis puslapis
      // Kiti puslapiai
      // Skaidrės
      // Paslaugos
      // ELTA
      // Kontaktai
      // Temos
      // Ženkliukai
      // Pranešimų spaudai prenumeratoriai
      // Pranešimų spaudai prenumeratorių grupės
      // Vertimai
    // ADMIN
      // Įmonės
      // Vartotojai
      // Demo
      // Darbuotojai
      // Skyriai
      // Logs
  */

  const items = [
    { title: strings.navigation.returnToMainAdmin, href: `${parameters.websiteUrl}/lt/backend`, current: false },
    { title: strings.navigation.companies, href: '/companies', current: false },
  ];

  return (
    <div className="flex grow flex-col overflow-y-auto bg-white border-r border-gray-200 pb-4">
      <div className="flex h-[77px] shrink-0 items-center">
        <img className="h-[21px] ml-[40px] w-auto" src={IMAGES.LOGOTYPES.PRIMARY} alt="ELTA" />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul className="mx-0">
              {items.map((item) => (
                <li key={item.title}>
                  <a
                    href={item.href}
                    className={classNames(
                      'border-l-[15px] border-gray-200',
                      item.current
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                      'group flex gap-x-3 p-2 text-xs leading-6 no-underline uppercase border-b border-b-gray-100',
                    )}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default SidebarComponent;
