import classNames from 'classnames';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import { getHumanDate, getMiliseconds } from '../../services/formatter/date';
import CommonButtonDefault from '../common/buttons/default';
import StyledError from '../common/form/styled-error';
import { Comment } from '../../entities/comment';
import { createComment } from '../../services/api/comments';
import { useAuth } from '../../hooks/use-auth';
import { FirstLetter } from '../../services/formatter/createAvatar';
import CommentComponent from './comment';
import AlertPrimary from '../common/alerts/alert-primary';
import { useComments } from '../../hooks/use-comments';
import CommonAnimatedSpinner from '../common/animated/spinner';
import { strings } from '../../localization/strings';

export default function CommentsComponent() {
  const { id } = useParams();
  const { setComments, comments } = useComments();
  const { token, user: currentUser } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);

  type Inputs = {
    description: string;
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (currentUser?.id) {
        setIsLoading(true);

        const formData: Partial<Comment> = { ...data, type: 'comment', user_id: currentUser.id };

        const res = await createComment(token, Number(id), formData);

        if (res) {
          setIsLoading(false);
          setComments([...comments, res]);
          setIsSubmitSuccessful(true);

          setTimeout(() => {
            setIsSubmitSuccessful(false);
          }, 2000);

          reset();
        }
      }
    } catch (e) {
      setIsLoading(false);
      // if (e instanceof Error) {
      //   setError('selectedPatientId', { message: e.message });
      // }
    }
  };

  return (
    <>
      <h3 className="text-sm font-medium leading-6 text-gray-900">Komentarai</h3>
      <ul className="mt-4 space-y-4">
        {comments &&
          comments.map((itemC, idx) => (
            <li key={`activity-${itemC.id}-${idx + 1}`} className="relative flex gap-x-4">
              <div
                className={classNames(
                  idx === comments.length - 1 ? 'h-6' : '-bottom-6',
                  'absolute left-0 top-0 flex w-6 justify-center',
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>
              {itemC.type === 'comment' ? (
                <CommentComponent item={itemC} />
              ) : (
                <>
                  <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                    {itemC.type === 'paid' ? (
                      <CheckCircleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                    ) : (
                      <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                    )}
                  </div>
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">{itemC.user_name}</span> {itemC.type} the invoice.
                  </p>
                  <time dateTime={itemC.updated_at} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                    {getHumanDate(getMiliseconds(itemC.updated_at))}
                  </time>
                </>
              )}
            </li>
          ))}
      </ul>

      {/* New comment form */}
      <div className="mt-6 flex gap-x-3">
        <div className="relative mt-3 h-6 w-6 flex rounded-full bg-primary text-white items-center justify-center text-xs font-semibold shrink-0">
          {currentUser && FirstLetter(currentUser.name)}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="relative flex-auto">
          {isLoading && (
            <div className="py-2 text-xs">
              <CommonAnimatedSpinner sm />
            </div>
          )}
          {errors.description && <StyledError>Komentaras privalomas</StyledError>}
          {isSubmitSuccessful && (
            <div className="mb-2">
              <AlertPrimary size="xs" type="success" text="Komentaras sėkmingai sukurtas" />
            </div>
          )}
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="description" className="sr-only">
              Komentaras
            </label>
            <textarea
              rows={2}
              id="description"
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Pridėkite savo komentarą..."
              {...register('description', { required: true })}
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <CommonButtonDefault secondary type="submit">
              {strings.button.comment}
            </CommonButtonDefault>
          </div>
        </form>
      </div>
    </>
  );
}
