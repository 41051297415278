import axios from 'axios';

import { Company } from '../../entities/company';
import { parameters } from '../../constants/parameters';

export const destroy = async (token: string, id: number): Promise<Company> => {
  const url = `${parameters.api.baseUrl}/companies-documents/${id}`;

  const res = await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};
