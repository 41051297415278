import React from 'react';
import { ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/20/solid';

interface ComponentProps {
  title?: string;
  text?: string;
  type?: string;
  marginTop?: string;
  size?: string;
}

function AlertPrimary({ title, text, type, marginTop, size }: ComponentProps) {
  return (
    <div
      className={`${size === 'xs' ? 'p-2 rounded' : 'p-4 md:p-5 rounded-2xl'}  
      ${type === 'failed' && 'bg-red-50'}
      ${type === 'warning' && 'bg-yellow-50 text-yellow-700'} 
      ${type === 'danger' && 'bg-red-50'} 
      ${type === 'success' && 'bg-green-50 text-green-800'}  ${marginTop}`}
    >
      <div className="flex-row flex items-center">
        <div className={`${size === 'xs' ? 'pl-2' : 'pl-4'}`}>
          {type === 'success' && (
            <div
              className={`mx-auto flex items-center justify-center ${
                size === 'xs' ? 'h-5 w-5' : 'h-8 w-8'
              } rounded-full bg-[#4ade80] shrink-0`}
            >
              <svg
                className={`text-white ${size === 'xs' ? 'h-4 w-4' : 'h-6 w-6'}`}
                x-description="Heroicon name: outline/check"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
          )}
          {type === 'danger' && (
            <XCircleIcon
              className={`mx-auto md:mb-0 text-red-500 ${size === 'xs' ? 'h-5 w-5' : 'h-8 w-8 mb-2 md:h-10 md:w-10'}`}
              aria-hidden="true"
            />
          )}
          {type !== 'danger' && type !== 'success' && (
            <ExclamationTriangleIcon
              className={`mb-2 mx-auto md:mb-0 md:h-9 md:w-9 text-gray-700 
              ${type === 'danger' && 'text-red-500'}
              ${type === 'warning' && 'text-yellow-400'}
              ${size === 'xs' ? 'h-5 w-5' : 'h-8 w-8'}
              `}
              aria-hidden="true"
            />
          )}
        </div>

        <div className={`${size === 'xs' ? 'pl-2' : 'pl-4'}`}>
          {title && <h3 className={`font-bold font-base ${size === 'xs' && 'text-sm'}`}>{title}</h3>}
          {text && <div className={`mt-1 ${size === 'xs' ? 'text-xs' : 'text-sm'}`}>{text}</div>}
        </div>
      </div>
    </div>
  );
}
AlertPrimary.defaultProps = {
  type: 'primary',
  title: '',
  text: '',
  marginTop: 'mt-0',
  size: '',
};

export default AlertPrimary;
