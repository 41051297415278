import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AuthContextProvider } from '../context/auth';
import Root from './root/root';
import { ModalsContextProvider } from '../context/modal';

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ModalsContextProvider>
        <AuthContextProvider>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </AuthContextProvider>
      </ModalsContextProvider>
    </QueryClientProvider>
  );
}
