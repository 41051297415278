import DatePicker from 'react-datepicker';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useCompany } from '../../../hooks/use-company';
import StyledInput from '../../../components/common/form/styled-input';
import { useAuth } from '../../../hooks/use-auth';
import StyledError from '../../../components/common/form/styled-error';
import { strings } from '../../../localization/strings';
import CommonButtonDefault from '../../../components/common/buttons/default';
import * as companiesService from '../../../services/api/companies';
import { getUnixTimestamp, isValidSubscriptionDates } from '../../../services/formatter/date';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import StyledSwitch from '../../../components/common/form/styled-switch';
import Tooltip from '../../../components/common/tooltip';

type InputsPressrelease = {
  pressrelease_active: number;
  pressrelease_subscription_dates: number;
  pressrelease_create_limit: number;
  pressrelease_creation_valid_from: Date;
  pressrelease_creation_valid_to: Date;
  can_publish_calendar: number;
  can_publish_pressrelease: number;
};

function ServicesPressReleaseForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const { token } = useAuth();
  const { updateCompany, companyDetails } = useCompany();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<InputsPressrelease>({
    defaultValues: {
      pressrelease_active: companyDetails.pressrelease_active,
      can_publish_calendar: companyDetails.can_publish_calendar,
      can_publish_pressrelease: companyDetails.can_publish_pressrelease,
      pressrelease_create_limit: companyDetails.pressrelease_create_limit,
      pressrelease_creation_valid_from: companyDetails.pressrelease_creation_valid_from
        ? new Date(companyDetails.pressrelease_creation_valid_from * 1000)
        : undefined,
      pressrelease_creation_valid_to: companyDetails.pressrelease_creation_valid_to
        ? new Date(companyDetails.pressrelease_creation_valid_to * 1000)
        : undefined,
      // subscription_dates: companyDetails ? new Date(companyDetails.subscription_dates * 1000) : undefined,
    },
  });

  const onSubmitPressRelease: SubmitHandler<InputsPressrelease> = async (data) => {
    try {
      if (companyDetails?.id) {
        setIsLoading(true);

        const formData = {
          ...data,
          pressrelease_active: data.pressrelease_active ? 1 : 0,
          pressrelease_creation_valid_from:
            data.pressrelease_creation_valid_from && data.pressrelease_active
              ? getUnixTimestamp(data.pressrelease_creation_valid_from)
              : null,
          pressrelease_creation_valid_to:
            data.pressrelease_creation_valid_to && data.pressrelease_active
              ? getUnixTimestamp(data.pressrelease_creation_valid_to)
              : null,
        };

        const res = await companiesService.update(token, companyDetails?.id, formData);

        if (res) {
          setIsLoading(false);
          setIsSubmitSuccessful(true);

          updateCompany(formData);

          setTimeout(() => {
            setIsSubmitSuccessful(false);
          }, 2000);
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  return (
    <>
      {error && (
        <div className="max-w-7xl mx-auto my-5">
          <AlertPrimary type="danger" text={error} />
        </div>
      )}
      {isSubmitSuccessful && (
        <div className="mb-2">
          <AlertPrimary size="xs" type="success" text="Sėkmingai atnaujinta" />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmitPressRelease)}>
        <div className="bg-white sm:rounded-lg overflow-hidden ring-1 ring-gray-900/5">
          <div className="p-4 font-medium leading-6 bg-gray-50 text-gray-900 flex flex-row">
            <h3>Pranešimų spaudai publikavimas</h3>
            <div className="ml-auto">
              <CommonButtonDefault type="submit" isLoading={isLoading} xs primary>
                Išsaugoti
              </CommonButtonDefault>
            </div>
            <div className="ml-4">
              <Tooltip
                disabled={
                  !isValidSubscriptionDates(
                    watch('pressrelease_creation_valid_from'),
                    watch('pressrelease_creation_valid_to'),
                  )
                }
                title={
                  <StyledSwitch
                    name="pressrelease_active"
                    control={control}
                    disabled={isValidSubscriptionDates(
                      watch('pressrelease_creation_valid_from'),
                      watch('pressrelease_creation_valid_to'),
                    )}
                  />
                }
              >
                Norėdami išjungti paslaugą, ištrinkite jos galiojimo laiką
              </Tooltip>
            </div>
          </div>

          {watch('pressrelease_active') === 1 && (
            <div className="grid grid-cols-3 md:grid-cols-4 gap-x-4 p-4">
              <div className="col-span-1">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Data nuo
                </label>
                <div className="mt-1">
                  <Controller
                    control={control}
                    name="pressrelease_creation_valid_from"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        // monthsShown={2}
                        showYearDropdown
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        selected={field.value ? new Date(field.value) : undefined}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        disabledKeyboardNavigation
                      />
                    )}
                  />
                  {errors.pressrelease_creation_valid_from && (
                    <StyledError>{strings.error.fieldIsRequired}</StyledError>
                  )}
                </div>
              </div>
              <div className="col-span-1">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Data iki
                </label>
                <div className="mt-1">
                  <Controller
                    control={control}
                    name="pressrelease_creation_valid_to"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        showYearDropdown
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        selected={field.value ? new Date(field.value) : undefined}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        disabledKeyboardNavigation
                      />
                    )}
                  />
                  {errors.pressrelease_creation_valid_to && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
                </div>
              </div>
              <div className="col-span-1">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Pranešimų spaudai kiekis
                </label>
                <div className="mt-1">
                  <StyledInput noValidation type="number" {...register('pressrelease_create_limit')} />
                </div>
                <div className="mt-1 text-sm text-gray-500" id="email-description">
                  {companyDetails.pressrelease_create_limit > 0 && (
                    <>
                      Pranešimų spaudai likutis:{' '}
                      {companyDetails.pressrelease_create_limit - companyDetails.pressrelease_created_amount}
                      <br />
                    </>
                  )}

                  <div className="mt-1 text-sm text-gray-500" id="email-description">
                    Jeigu reikia neriboti - palikite tuščią lauką
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
}

export default ServicesPressReleaseForm;
