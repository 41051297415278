import React, { createContext, useState, useMemo, useEffect, useCallback } from 'react';

import { Company } from '../entities/company';
import { CompanyAdminStructure } from '../entities/company-admin-structure';
import { Nullable } from '../entities/nullable';

interface ComponentProps {
  children: React.ReactNode;
  companyDetails: Company;
  companyStructure: CompanyAdminStructure;
}

interface ContextValue {
  companyDetails: Company;
  setCompany: (company: Company) => void;
  updateCompany: (company: Partial<Nullable<Company>>) => void;
  companyStructure: CompanyAdminStructure;
  setCompanyStructure: (structure: CompanyAdminStructure) => void;
  updateCompanyStructure: (structure: Partial<CompanyAdminStructure>) => void;
}

const CompanyContext = createContext<ContextValue>({
  companyDetails: {} as Company,
  setCompany: () => null,
  updateCompany: () => null,
  companyStructure: {} as CompanyAdminStructure,
  setCompanyStructure: () => null,
  updateCompanyStructure: () => null,
});

function CompanyContextProvider({ children, companyDetails, companyStructure }: ComponentProps) {
  const [companyInfo, setCompanyInfo] = useState<Company>(companyDetails || {});
  const [structure, setStructure] = useState<CompanyAdminStructure>(companyStructure || {});

  useEffect(() => {
    setCompanyInfo(companyDetails);
  }, [companyDetails]);

  const updateCompany = useCallback(
    (company: Partial<Nullable<Company>>) => {
      const updatedCompany = {
        ...companyInfo,
        ...company,
      } as unknown as Company;

      // Update subscription status
      updatedCompany.has_subscription =
        updatedCompany.news_active === 1 ||
        updatedCompany.photos_active === 1 ||
        updatedCompany.pressrelease_active === 1;

      setCompanyInfo(updatedCompany);
    },
    [companyInfo],
  );

  useEffect(() => {
    setStructure(companyStructure);
  }, [companyStructure]);

  const updateCompanyStructure = useCallback(
    (structureNew: Partial<CompanyAdminStructure>) => {
      setStructure({ ...structure, ...structureNew });
    },
    [structure],
  );

  const contextValue = useMemo(
    () => ({
      companyDetails: companyInfo,
      setCompany: setCompanyInfo,
      updateCompany,
      companyStructure: structure,
      setCompanyStructure: setStructure,
      updateCompanyStructure,
    }),
    [companyInfo, updateCompany, setCompanyInfo, setStructure, structure, updateCompanyStructure],
  );

  return <CompanyContext.Provider value={contextValue}>{children}</CompanyContext.Provider>;
}

export { CompanyContext, CompanyContextProvider };
