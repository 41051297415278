import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { TrashIcon } from '@heroicons/react/20/solid';

import StyledLabel from '../../../components/common/form/styled-label';
import StyledInput from '../../../components/common/form/styled-input';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { useAuth } from '../../../hooks/use-auth';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import CommonAnimatedSpinner from '../../../components/common/animated/spinner';
import StyledError from '../../../components/common/form/styled-error';
import { strings } from '../../../localization/strings';
import { useCompany } from '../../../hooks/use-company';
import { SelectValues } from '../../../entities/select-values';
import * as companiesService from '../../../services/api/companies';
import { fromJsDate, sortByDateAsc } from '../../../services/formatter/date';
import { PeriodicityOptions } from '../../../entities/company';

type Inputs = {
  payment_amount: string;
  payment_periodicity: PeriodicityOptions;
  invoice_email: number;
  invoice_einvoice: number;
  payment_dates?: { date: Date }[];
};

interface ComponentProps {
  onCancel: (data: boolean) => void;
  onSuccess: (data: boolean) => void;
}

export default function InvoicesForm({ onCancel, onSuccess }: ComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const { token } = useAuth();
  const { updateCompany, companyDetails: company } = useCompany();

  const periodicityOptions: SelectValues[] = [
    { value: 'monthly', label: 'Kas mėnesį' },
    { value: 'singleUse', label: 'Vienkartinis' },
    { value: 'individual', label: 'Individualus' },
  ];

  const dateObjects = company?.payment_dates?.map((date) => ({ date: new Date(date) }));

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      payment_amount: company?.payment_amount,
      payment_periodicity: company?.payment_periodicity,
      invoice_einvoice: company?.invoice_einvoice,
      invoice_email: company?.invoice_email,
      payment_dates: company?.payment_dates ? dateObjects : [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'payment_dates',
    control,
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (company?.id) {
        setIsLoading(true);

        let formDatesObj;

        if (data.payment_dates) {
          formDatesObj = sortByDateAsc(data.payment_dates);
        }

        const formData = {
          ...data,
          payment_dates: formDatesObj ? formDatesObj.map((date) => fromJsDate(date.date)) : undefined,
        };

        const res = await companiesService.update(token, company?.id, formData);

        if (res) {
          setIsLoading(false);
          updateCompany(formData);
          onSuccess(true);
          onCancel(true);
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  // React.useEffect(() => {
  //   remove(0);
  // }, [remove]);

  return (
    <div className="p-3 border-gray-100 border-b">
      {isLoading && (
        <div className="py-2 text-xs">
          <CommonAnimatedSpinner sm />
        </div>
      )}
      {error && <AlertPrimary type="danger" text={error} />}

      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <StyledLabel htmlFor="payment_amount">Suma</StyledLabel>
          <StyledInput
            id="payment_amount"
            className={` ${errors.payment_amount && `!border-red-500`} `}
            {...register('payment_amount', { required: true })}
          />
          {errors.payment_amount && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
        </div>
        <div>
          <StyledLabel htmlFor="payment_amount">Periodiškumas</StyledLabel>

          <Controller
            control={control}
            name="payment_periodicity"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                options={periodicityOptions}
                placeholder="Pasirinkite mokėjimų periodiškumą"
                value={periodicityOptions.find((option) => option.value === field.value)}
                onChange={(e) => {
                  field.onChange(e?.value);
                }}
              />
            )}
          />
          {errors.payment_periodicity && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
        </div>

        <div>
          {watch('payment_periodicity') === 'individual' && (
            <div>
              <legend className="text-sm font-semibold leading-6 text-gray-700 mb-2">
                Pasirinkite mokėjimo dienas
              </legend>

              {fields &&
                fields.map((item, index) => (
                  <div className="flex space-x-3 mb-3 items-center" key={item.id}>
                    <div>
                      <Controller
                        control={control}
                        name={`payment_dates.${index}.date` as const}
                        render={({ field }) => (
                          <DatePicker
                            showYearDropdown
                            onChange={field.onChange}
                            selected={field.value}
                            dateFormat="yyyy-MM-dd"
                          />
                        )}
                      />
                    </div>
                    <div>
                      <CommonButtonDefault xs link onClick={() => remove(index)}>
                        <TrashIcon
                          className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 hover:text-red-600"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Delete</span>
                      </CommonButtonDefault>
                    </div>
                  </div>
                ))}
              <CommonButtonDefault secondary sm type="button" onClick={() => append({ date: new Date() })}>
                Add Date Picker
              </CommonButtonDefault>
            </div>
          )}
        </div>

        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-gray-900">Sąskaitas pateikti</legend>
          <div className="mt-2 space-y-4">
            <div className="flex items-center">
              <input
                id="invoice_email"
                {...register('invoice_email')}
                // defaultChecked={notificationMethod.id === 'email'}
                type="checkbox"
                className="h-4 w-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500 checked:bg-blue-900 checked:hover:bg-blue-900"
              />
              <StyledLabel htmlFor="invoice_email" className="!mb-0 ml-2">
                El. paštu
              </StyledLabel>
            </div>
            <div className="flex items-center">
              <input
                id="invoice_einvoice"
                {...register('invoice_einvoice')}
                type="checkbox"
                className="h-4 w-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500 checked:bg-blue-900 checked:hover:bg-blue-900"
              />
              <StyledLabel htmlFor="invoice_einvoice" className="!mb-0 ml-2">
                E. sąskaita
              </StyledLabel>
            </div>
          </div>
        </fieldset>
        <div className="flex space-x-4 mt-4 justify-end">
          <CommonButtonDefault secondary type="button" onClick={() => onCancel(true)}>
            Atšaukti
          </CommonButtonDefault>
          <CommonButtonDefault primary type="submit">
            Išsaugoti
          </CommonButtonDefault>
        </div>
      </form>
    </div>
  );
}
