import { useContext } from 'react';

import { ModalsContext } from '../context/modal';

export const useModal = () => {
  const context = useContext(ModalsContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useModal was used outside of its Provider');
  }

  return context;
};
