import React from 'react';
import tw, { styled } from 'twin.macro';

import CommonAnimatedSpinner from './spinner';

const LoaderBackdrop = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full bg-white opacity-75`}
  z-index: 900
`;

const LoaderContainer = styled.div`
  ${tw`fixed top-0 left-0 w-screen h-screen flex justify-center items-center `}
  z-index: 950;
`;

function CommonAnimatedLoader() {
  return (
    <>
      <LoaderBackdrop />
      <LoaderContainer>
        <CommonAnimatedSpinner />
      </LoaderContainer>
    </>
  );
}

export default CommonAnimatedLoader;
