import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Company } from '../../entities/company';
import { Pagination } from '../../entities/pagination';
import { fromTimestamp, getDurationFromDateString, getDurationSeconds } from '../../services/formatter/date';
import { strings } from '../../localization/strings';
import Badge from '../common/badge';
import YesNoBadge from '../common/badge/yes_no';
import PaginationComponent from '../common/pagination';
import TableHeaderSortable from '../table/header-sortable';

interface ComponentProps {
  orders: Company[];
  sort: string;
  pagination: Pagination;
  onUpdate: (pageNo: number, sort: string) => void;
}

export default function CompaniesTableComponent({ orders, sort, pagination, onUpdate }: ComponentProps) {
  const isSorted = (column: string) => sort.indexOf(column) !== -1;

  const isAscending = (column: string) => isSorted(column) && sort.indexOf('-') === -1;

  const onChangeSort = (column: string) => {
    onUpdate(0, isAscending(column) ? `-${column}` : column);
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-4 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <TableHeaderSortable column="created_at" current={sort} onUpdate={onChangeSort}>
                        {strings.companies.registered}
                      </TableHeaderSortable>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {strings.companies.demo}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {strings.companies.name}
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <TableHeaderSortable column="valid_to" current={sort} onUpdate={onChangeSort}>
                        {strings.companies.news_subscription}
                      </TableHeaderSortable>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {strings.companies.expires_at}
                    </th>
                    <th scope="col" className="px-4 py-3">
                      <TableHeaderSortable
                        column="pressrelease_creation_valid_to"
                        current={sort}
                        onUpdate={onChangeSort}
                      >
                        {strings.companies.press_releases}
                      </TableHeaderSortable>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {strings.companies.expires_at}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => (
                    <tr
                      key={order.id}
                      className={classNames(
                        order.is_active === 0 ? 'bg-red-50' : '',
                        order.is_active && index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                      )}
                    >
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-900 w-24">{order.id}</td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-900 w-24">
                        {fromTimestamp(order.created_at)}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                        <YesNoBadge state={order.is_demo === 1} />
                      </td>
                      <td className="px-4 py-2 text-sm text-gray-500">
                        <Link
                          to={`/companies/view/${order.id}`}
                          rel="noopener noreferrer"
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          {order.name}
                        </Link>
                        <div className="text-xs text-gray-400">{order.email}</div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-900 w-24">
                        {order.valid_from > Date.now() / 1000 ? (
                          <div className="mb-1">
                            <Badge status="danger" text={`Nuo ${fromTimestamp(order.valid_from * 1000)}`} />
                          </div>
                        ) : (
                          ''
                        )}

                        {order.valid_to ? (
                          <Badge
                            status={getDurationSeconds(order.valid_to * 1000) > 0 ? 'success' : 'danger'}
                            text={getDurationFromDateString(order.valid_to * 1000)}
                          />
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-900 w-24">
                        {fromTimestamp(order.valid_to * 1000)}
                        <div className="text-xs text-gray-400">{fromTimestamp(order.valid_from * 1000)}</div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-900 w-24">
                        {order.pressrelease_creation_valid_from > Date.now() / 1000 ? (
                          <div className="mb-1">
                            <Badge
                              status="danger"
                              text={`Nuo ${fromTimestamp(order.pressrelease_creation_valid_from * 1000)}`}
                            />
                          </div>
                        ) : (
                          ''
                        )}

                        {order.pressrelease_creation_valid_to ? (
                          <Badge
                            status={
                              getDurationSeconds(order.pressrelease_creation_valid_to * 1000) > 0 ? 'success' : 'danger'
                            }
                            text={getDurationFromDateString(order.pressrelease_creation_valid_to * 1000)}
                          />
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-900 w-24">
                        {order.pressrelease_creation_valid_to ? (
                          <>
                            {fromTimestamp(order.pressrelease_creation_valid_to * 1000)}
                            <div className="text-xs text-gray-400">
                              {fromTimestamp(order.pressrelease_creation_valid_from * 1000)}
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <PaginationComponent pagination={pagination} onChangePage={(page) => onUpdate(page, sort)} />
    </>
  );
}
