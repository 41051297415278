import React, { createContext, useState, useCallback, useMemo, useEffect } from 'react';

import { Comment } from '../entities/comment';

interface ComponentProps {
  children: React.ReactNode;
  comments: Comment[];
  // onModifyComments?: (comments: Comment[], commentsLogged: Comment[]) => void;
}

interface ContextValue {
  setComments: (comments: Comment[]) => void;
  comments: Comment[];

  addComments: (comments: Comment[]) => void;
  removeComment: (comment: Comment) => void;
  // addReplies: (comment: Comment, replies: Comment[]) => void;
  updateComment: (id: number, comment: Partial<Comment>) => void;
}

const CommentsContext = createContext<ContextValue>({
  setComments: () => null,
  addComments: () => null,
  removeComment: () => null,
  // addReplies: () => null,
  updateComment: () => null,
  comments: [],
});

function CommentsContextProvider({ children, comments }: ComponentProps) {
  const [allComments, setAllComments] = useState<Comment[]>(comments || []);

  useEffect(() => {
    setAllComments(comments);
  }, [comments]);

  const addComments = useCallback(
    (newComments: Comment[]) => {
      setAllComments([...(allComments || []), ...newComments]);
    },
    [allComments],
  );

  const removeComment = useCallback(
    (comment: Comment) => {
      allComments.splice(allComments.indexOf(comment), 1);

      setAllComments([...allComments]);
    },
    [allComments],
  );

  const updateComment = useCallback(
    (id: number, comment: Partial<Comment>) => {
      const originalComment = allComments.find((item) => item.id === id);

      if (originalComment) {
        const originalCommentIndex = allComments.indexOf(originalComment);

        allComments[originalCommentIndex] = { ...originalComment, ...comment };
      }

      setAllComments([...allComments]);
    },
    [allComments],
  );

  const contextValue = useMemo(
    () => ({
      comments: allComments,
      setComments: setAllComments,
      addComments,
      removeComment,
      updateComment,
    }),
    [addComments, removeComment, updateComment, setAllComments, allComments],
  );

  return <CommentsContext.Provider value={contextValue}>{children}</CommentsContext.Provider>;
}

// CommentsContextProvider.defaultProps = {
//   // onModifyComments: undefined,
// };

export { CommentsContext, CommentsContextProvider };
