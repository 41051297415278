import React from 'react';
import classNames from 'classnames';

export default function Badge({ status, text }: { status: string | undefined; text: string }) {
  return (
    <span
      className={classNames('inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ', {
        'bg-yellow-100 text-yellow-800': status === 'warning',
        'bg-red-100 text-red-800': status === 'danger',
        'bg-green-100 text-green-800': status === 'success',
        'bg-blue-100 text-blue-800': status === 'primary',
        'bg-gray-100 text-gray-800': status === 'secondary',
      })}
    >
      {text}
    </span>
  );
}
