import React, { ReactNode, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Popover } from '@headlessui/react';
import tw, { styled } from 'twin.macro';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

const TooltipArrow = styled.div<{ placement: string }>`
  visibility: hidden;

  ${(props) => (props.placement === 'top' ? 'bottom: -4px;' : '')}
  ${(props) => (props.placement === 'bottom' ? 'top: -4px;' : '')}
  ${(props) => (props.placement === 'left' ? 'right: -4px;' : '')}
  ${(props) => (props.placement === 'right' ? 'left: -4px;' : '')}

  &,
  &::before {
    ${tw`bg-white`}
    position: absolute;
    width: 8px;
    height: 8px;
  }

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;

interface ComponentProps {
  title?: string | ReactNode;
  children: ReactNode;
  disabled?: boolean;
}

const timeoutDuration = 120;

function Tooltip({ children, title, disabled }: ComponentProps) {
  const timeOutRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>();
  const { styles, attributes, state } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  });

  const handleEnter = (isOpen: boolean) => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
    !isOpen && referenceElement?.click();
  };

  const handleLeave = (isOpen: boolean) => {
    timeOutRef.current = setTimeout(() => {
      isOpen && referenceElement?.click();
    }, timeoutDuration);
  };

  if (disabled) {
    return <span>{title}</span>;
  }

  return (
    <Popover className="ml-3 print:hidden">
      {({ open }) => (
        <span onMouseEnter={() => handleEnter(open)} onMouseLeave={() => handleLeave(open)}>
          <Popover.Button className="outline-none" ref={(ref: HTMLButtonElement) => setReferenceElement(ref)}>
            {title || (
              <div className="md:text-2xl text-xl text-gray-600 hover:text-gray-800 font-medium mt-0.5">
                <InformationCircleIcon />
              </div>
            )}
          </Popover.Button>
          <Popover.Panel
            ref={(ref: HTMLDivElement) => setPopperElement(ref)}
            style={styles.popper}
            {...attributes.popper}
            className="bg-white text-md font-normal ring ring-gray-900/5 leading-normal p-3 mx-6 md:mx-0 rounded-lg shadow-md z-[2]"
          >
            <TooltipArrow
              placement={state?.placement || 'top'}
              ref={(ref) => setArrowElement(ref)}
              style={styles.arrow}
            />
            {children}
          </Popover.Panel>
        </span>
      )}
    </Popover>
  );
}

Tooltip.defaultProps = {
  title: '',
  disabled: false,
};

export default Tooltip;
