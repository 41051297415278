import axios, { AxiosError } from 'axios';

import { Pagination } from '../../entities/pagination';
import ValidationError from '../../entities/validation-error';
import { Nullable } from '../../entities/nullable';

export const list = async <T>(
  token: string,
  url: string,
  paramString?: object,
): Promise<{ items: T[]; pagination: Pagination }> => {
  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params: paramString,
    });

    return {
      items: res.data,
      pagination: {
        total: parseInt(res.headers['x-total'], 10),
        current: parseInt(res.headers['x-page'], 10),
        pages: parseInt(res.headers['x-total-pages'], 10),
        size: parseInt(res.headers['x-size'], 10),
      },
    };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

export const one = async <T>(token: string, url: string): Promise<T> => {
  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const update = async <FormItem, ResponseItem>(
  token: string,
  url: string,
  data: Partial<Nullable<FormItem>>,
): Promise<ResponseItem | undefined> => {
  try {
    const res = await axios.put(url, data, { headers: { Authorization: `Bearer ${token}` } });

    return res.data;
  } catch (e) {
    const err = e as AxiosError<{ message: string; errors: { [key: string]: string[] } }>;

    if (err.response) {
      if (err.response.data.errors) {
        throw new ValidationError({
          message: err.response.data.message,
          errors: Object.entries(err.response.data.errors).map(([key, value]) => {
            return {
              key,
              value: value[0],
            };
          }),
        });
      } else {
        throw new Error(err.response.data.message);
      }
    }
  }

  return undefined;
};

export const create = async <FormItem, ResponseItem>(
  token: string,
  url: string,
  data: Partial<FormItem>,
): Promise<ResponseItem | undefined> => {
  try {
    const res = await axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });

    return res.data;
  } catch (e) {
    const err = e as AxiosError<{ message: string; errors: { [key: string]: string[] } }>;

    if (err.response) {
      if (err.response.data.errors) {
        throw new ValidationError({
          message: err.response.data.message,
          errors: Object.entries(err.response.data.errors).map(([key, value]) => {
            return {
              key,
              value: value[0],
            };
          }),
        });
      } else {
        throw new Error(err.response.data.message);
      }
    }
  }

  return undefined;
};
