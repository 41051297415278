import React from 'react';

import ServicesNewsForm from './services-news-form';
import ServicesPressReleaseForm from './services-pressrelease-form';
import ServicesPhotosForm from './services-photos-form';

function ServicesTabComponent() {
  return (
    <>
      <ServicesPressReleaseForm />
      <ServicesNewsForm />
      <ServicesPhotosForm />
    </>
  );
}

export default ServicesTabComponent;
