import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import React, { useState } from 'react';

import Tooltip from '../../../components/common/tooltip';
import CommonButtonDefault from '../../../components/common/buttons/default';
import { useAuth } from '../../../hooks/use-auth';
import { useCompany } from '../../../hooks/use-company';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import StyledError from '../../../components/common/form/styled-error';
import { strings } from '../../../localization/strings';
import { getUnixTimestamp, isValidSubscriptionDates } from '../../../services/formatter/date';
import * as companiesService from '../../../services/api/companies';
import LabelWithError from '../../../components/common/form/label-with-error';
import { AllowedCategory, AllowedCategoryCountry } from '../../../entities/company';
import StyledSwitch from '../../../components/common/form/styled-switch';

type Inputs = {
  news_active: number;
  valid_from: Date;
  valid_to: Date;
};

function ServicesNewsForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const { token } = useAuth();
  const { updateCompany, companyDetails, companyStructure: structure } = useCompany();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      news_active: companyDetails.news_active,
      valid_from: companyDetails.valid_from ? new Date(companyDetails.valid_from * 1000) : undefined,
      valid_to: companyDetails.valid_to ? new Date(companyDetails.valid_to * 1000) : undefined,
    },
  });

  const createEmptyAllowedCategories = (
    languageId: number,
    country: keyof AllowedCategory,
    field: keyof AllowedCategoryCountry,
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    defaultValue: any,
  ) => {
    if (typeof companyDetails.allowed_categories[languageId] === 'undefined') {
      companyDetails.allowed_categories[languageId] = {
        [country]: {
          [field]: [],
        },
      } as unknown as AllowedCategory;
    } else if (typeof companyDetails.allowed_categories[languageId][country] === 'undefined') {
      companyDetails.allowed_categories[languageId][country] = {
        [field]: [],
      } as unknown as AllowedCategoryCountry;
    } else if (typeof companyDetails.allowed_categories[languageId]?.[country]?.[field] === 'undefined') {
      companyDetails.allowed_categories[languageId][country][field] = defaultValue;
    }
  };

  const checked = (ids: number[] | undefined, selectedId: number) => {
    if (typeof ids === 'undefined') {
      return false;
    }

    return ids.indexOf(selectedId) !== -1;
  };

  const onToggleMultiple = (
    languageId: number,
    country: keyof AllowedCategory,
    field: Exclude<keyof AllowedCategoryCountry, 'guide'>,
    categories: number[],
    isChecked?: boolean,
  ) => {
    createEmptyAllowedCategories(languageId, country, field, []);

    const source = companyDetails.allowed_categories[languageId]?.[country]?.[field] as unknown as number[];

    if (isChecked) {
      categories.forEach((category) => {
        if (source.indexOf(category) === -1) {
          source.push(category);
        }
      });
    } else {
      source.splice(0, source.length);
    }

    updateCompany({ ...companyDetails });
  };

  const onUpdateSingleCheckbox = (
    languageId: number,
    country: keyof AllowedCategory,
    field: 'guide',
    isChecked: boolean,
  ) => {
    createEmptyAllowedCategories(languageId, country, field, '0');

    if (isChecked) {
      companyDetails.allowed_categories[languageId][country][field] = '1';
    } else {
      companyDetails.allowed_categories[languageId][country][field] = '0';
    }

    updateCompany({ ...companyDetails });
  };

  const onUpdateCategory = (
    languageId: number,
    country: keyof AllowedCategory,
    field: Exclude<keyof AllowedCategoryCountry, 'guide'>,
    category: number,
    isChecked?: boolean,
  ) => {
    createEmptyAllowedCategories(languageId, country, field, []);

    const source = companyDetails.allowed_categories[languageId]?.[country]?.[field];

    if (isChecked) {
      source.push(category);
    } else {
      source.splice(source.indexOf(category), 1);
    }

    updateCompany({ ...companyDetails });
  };
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (companyDetails?.id) {
        setIsLoading(true);

        const formData = {
          news_active: data.news_active ? 1 : 0,
          valid_from: data.valid_from && data.news_active ? getUnixTimestamp(data.valid_from) : null,
          valid_to: data.valid_to && data.news_active ? getUnixTimestamp(data.valid_to) : null,
          allowed_categories: companyDetails.allowed_categories,
        };

        const res = await companiesService.update(token, companyDetails?.id, formData);

        if (res) {
          setIsLoading(false);
          setIsSubmitSuccessful(true);

          updateCompany(formData);

          setTimeout(() => {
            setIsSubmitSuccessful(false);
          }, 2000);
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  return (
    <>
      {error && (
        <div className="max-w-7xl mx-auto my-5">
          <AlertPrimary type="danger" text={error} />
        </div>
      )}
      {isSubmitSuccessful && (
        <div className="mb-2 mt-4">
          <AlertPrimary size="xs" type="success" text="Sėkmingai atnaujinta" />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white sm:rounded-lg overflow-hidden ring-1 ring-gray-900/5 mt-8">
          <div className="p-4 font-medium leading-6 bg-gray-50 text-gray-900 flex flex-row">
            <h3>Naujienų prenumerata</h3>
            <div className="ml-auto">
              <CommonButtonDefault type="submit" isLoading={isLoading} xs primary>
                Išsaugoti
              </CommonButtonDefault>
            </div>
            <div className="ml-4">
              <Tooltip
                disabled={!isValidSubscriptionDates(watch('valid_from'), watch('valid_to'))}
                title={
                  <StyledSwitch
                    name="news_active"
                    control={control}
                    disabled={isValidSubscriptionDates(watch('valid_from'), watch('valid_to'))}
                  />
                }
              >
                Norėdami išjungti paslaugą, ištrinkite jos galiojimo laiką
              </Tooltip>
            </div>
          </div>

          {watch('news_active') === 1 && (
            <div>
              <div className="grid grid-cols-2 md:grid-cols-5 gap-x-4 p-4">
                <div className="col-span-1">
                  <LabelWithError title="Prenumeratos pradžia" error={errors.valid_from?.message}>
                    <Controller
                      control={control}
                      name="valid_from"
                      render={({ field }) => (
                        <DatePicker
                          // monthsShown={2}
                          showYearDropdown
                          dateFormat="yyyy-MM-dd"
                          isClearable
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          selected={field.value ? new Date(field.value) : undefined}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          disabledKeyboardNavigation
                        />
                      )}
                    />
                  </LabelWithError>
                </div>
                <div className="col-span-1">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    Prenumeratos pabaiga
                  </label>
                  <div className="mt-1">
                    <Controller
                      control={control}
                      name="valid_to"
                      render={({ field }) => (
                        <DatePicker
                          showYearDropdown
                          dateFormat="yyyy-MM-dd"
                          isClearable
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          selected={field.value ? new Date(field.value) : undefined}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          disabledKeyboardNavigation
                        />
                      )}
                    />
                    {errors.valid_to && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
                  </div>
                </div>
              </div>
              <h4 className="p-4">Užsakytos kategorijos</h4>
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-4">
                {structure?.languages.map((language) => (
                  <div className={classNames('sm:col-span-1 border-gray-900/5 border-r')} key={language.id}>
                    <div className="text-center bg-gray-50 font-medium p-3">{language.name}</div>
                    <div className="px-2 py-2">
                      <div className="flex flex-row font-semibold">
                        <div className="text-sm flex-1 mt-1 text-right w-max line-clamp-1">&nbsp;</div>
                        <div className="ml-1 text-xs">LT</div>
                        <div className="ml-1 text-xs">UŽ</div>
                      </div>
                      <div className="flex flex-row font-semibold">
                        <div className="text-sm flex-1 mt-1 text-right w-max line-clamp-1">&nbsp;</div>
                        <div className="ml-1 text-xs">
                          <input
                            id="local-all"
                            name="local-all"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={(e) =>
                              onToggleMultiple(
                                language.id,
                                'country1',
                                'newsposts',
                                language.categories.map((a) => a.id),
                                e.target.checked,
                              )
                            }
                          />
                        </div>
                        <div className="ml-1 text-xs">
                          <input
                            id="foreign-all"
                            name="foreign-all"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={(e) =>
                              onToggleMultiple(
                                language.id,
                                'country2',
                                'newsposts',
                                language.categories.map((a) => a.id),
                                e.target.checked,
                              )
                            }
                          />
                        </div>
                      </div>
                      {language.categories.map((category) => (
                        <div key={category.id} className="flex flex-row">
                          <div className="text-sm flex-1 mt-1 text-right w-max line-clamp-1">
                            <Tooltip title={category.name}>{category.name}</Tooltip>
                          </div>
                          <div className="ml-1">
                            <input
                              id={`local-${category.id}`}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              onChange={(e) =>
                                onUpdateCategory(language.id, 'country1', 'newsposts', category.id, e.target.checked)
                              }
                              checked={checked(
                                companyDetails?.allowed_categories[language.id]?.country1?.newsposts,
                                category.id,
                              )}
                            />
                          </div>
                          <div className="ml-1">
                            <input
                              id={`local-${category.id}`}
                              name={`local-${category.id}`}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              onChange={(e) =>
                                onUpdateCategory(language.id, 'country2', 'newsposts', category.id, e.target.checked)
                              }
                              checked={checked(
                                companyDetails?.allowed_categories[language.id]?.country2?.newsposts,
                                category.id,
                              )}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="p-2 bg-gray-50">
                      <div className="flex flex-row">
                        <div className="text-sm flex-1 mt-1 text-right w-max line-clamp-1">Eltos Gidas</div>
                        <div className="ml-1">
                          <input
                            id="local-guide"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={(e) => onUpdateSingleCheckbox(language.id, 'country1', 'guide', e.target.checked)}
                            checked={companyDetails?.allowed_categories[language.id]?.country1?.guide === '1'}
                          />
                        </div>
                        <div className="ml-1">
                          <input
                            id="foreign-guide"
                            name="foreign-guide"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={(e) => onUpdateSingleCheckbox(language.id, 'country2', 'guide', e.target.checked)}
                            checked={companyDetails?.allowed_categories[language.id]?.country2?.guide === '1'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div>
                  <div className="text-center bg-gray-50 font-medium p-3">Užsienio agentūros</div>
                  <div className="px-2 py-2">
                    <div className="flex flex-row font-semibold">
                      <div className="text-sm flex-1 mt-1 text-right w-max line-clamp-1">&nbsp;</div>
                      <div className="ml-1 text-xs">&nbsp;</div>
                    </div>
                    <div className="flex flex-row font-semibold">
                      <div className="text-sm flex-1 mt-1 text-right w-max line-clamp-1">&nbsp;</div>
                      <div className="ml-1 text-xs">
                        <input
                          id="agencies-all"
                          name="agencies-all"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={(e) =>
                            onToggleMultiple(
                              1,
                              'country2',
                              'agencies',
                              structure?.agencies.map((a) => a.id),
                              e.target.checked,
                            )
                          }
                        />
                      </div>
                    </div>

                    {/*      <div class="small_label">{{ __('foreignAgenciesPhotos')}}</div> */}
                    {/*      @foreach (\App\Models\Agency::photoAgencies() as $agency) */}
                    {/*      <div class="multirow"> */}
                    {/*        <div class="multicolumn">EPA</div> */}
                    {/*        <div class="multicolumn"> */}
                    {/* <span> */}
                    {/*  <label class="simple_checkbox smaller"> */}
                    {/*    <input type="checkbox" value="1" name="allowed_categories[1][country2][agencies][{{ $agency->id }}]" @if (old('allowed_categories.1.country2.'.$agency->id, $company->isAgencyAllowed($agency->id))==1) checked @endif> */}
                    {/*    <span class="name"></span> */}
                    {/*  </label> */}
                    {/* </span> */}
                    {/*        </div> */}
                    {/*      </div> */}
                    {/*      @endforeach */}

                    {structure?.agencies.map((agency) => (
                      <div key={agency.id} className="flex flex-row">
                        <div className="text-sm flex-1 mt-1 text-right w-max line-clamp-1">{agency.name}</div>
                        <div className="ml-1">
                          <input
                            id={`agency-${agency.id}`}
                            name={`agency-${agency.id}`}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={(e) => onUpdateCategory(1, 'country2', 'agencies', agency.id, e.target.checked)}
                            checked={checked(companyDetails?.allowed_categories[1]?.country2?.agencies, agency.id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
}

export default ServicesNewsForm;
