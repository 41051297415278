import { PaperClipIcon, TrashIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';

import MyDropzone from '../../../components/common/form/dropzone';
import CommonButtonDefault from '../../../components/common/buttons/default';
import CommonConfirmationModal from '../../../components/modals/confirmation';
import { useCompany } from '../../../hooks/use-company';
import { CompanyDocument } from '../../../entities/company';
import { getHumanDate } from '../../../services/formatter/date';
import { strings } from '../../../localization/strings';
import * as companyDocuments from '../../../services/api/company-documents';
import { useAuth } from '../../../hooks/use-auth';
import { SimpleObject } from '../../../entities/simple-object';

function DocumentsBlock() {
  const { companyDetails, setCompany } = useCompany();
  const { token } = useAuth();

  const [confirmDelete, setConfirmDelete] = useState<SimpleObject<boolean>>({});

  const onRemoveFile = async (file: CompanyDocument) => {
    await companyDocuments.destroy(token, file.id);

    if (companyDetails.documents) {
      const documents = [...companyDetails.documents];

      documents.splice(documents.indexOf(file), 1);

      setCompany({ ...companyDetails, documents });
    }
  };

  const onDownloadFile = (file: CompanyDocument) => {
    document.location.href = file.url;
  };

  const onChangeStateDeleteConfirm = (documentId: number, state: boolean) => {
    setConfirmDelete({ ...confirmDelete, [documentId]: state });
  };

  type DocumentType = keyof typeof strings.documents.types;

  return (
    <div className="bg-white mb-8 sm:rounded-lg ring-1 ring-gray-900/5">
      <dt className="p-4 font-medium rounded-lg leading-6 text-gray-900 bg-gray-50">Dokumentai</dt>
      <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <ul className="divide-y divide-gray-100 border-t border-gray-200">
          {companyDetails.documents &&
            companyDetails.documents.map((document) => (
              <li
                key={`document-${document.id}`}
                className="flex items-center justify-between px-4 py-2 text-sm leading-6"
              >
                <div className="flex w-0 flex-1 items-center">
                  <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <div className="ml-2 min-w-0">
                    <div className="truncate font-medium">{document.filename}</div>
                    <div className="font-normal text-xs text-gray-500">
                      {strings.documents.types[document.type as unknown as DocumentType]}
                    </div>
                  </div>
                </div>
                <time dateTime={document.created_at} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                  {getHumanDate(Date.parse(document.created_at))}
                </time>

                <div className="ml-4 flex items-center flex-shrink-0">
                  <CommonButtonDefault xs link onClick={() => onDownloadFile(document)}>
                    <ArrowDownTrayIcon
                      className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 hover:text-indigo-600"
                      aria-hidden="true"
                    />
                  </CommonButtonDefault>

                  <CommonButtonDefault
                    xs
                    link
                    type="button"
                    onClick={() => onChangeStateDeleteConfirm(document.id, true)}
                  >
                    <TrashIcon
                      className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 hover:text-red-600"
                      aria-hidden="true"
                    />
                  </CommonButtonDefault>
                  <CommonConfirmationModal
                    open={confirmDelete[document.id] || false}
                    confirmed={() => onRemoveFile(document)}
                    cancelled={() => onChangeStateDeleteConfirm(document.id, false)}
                    title={strings.common.confirmation.deleteFile.title}
                    description={strings.common.confirmation.deleteFile.description}
                    buttonConfirm={strings.common.yes}
                    buttonCancel={strings.common.no}
                  />
                </div>
              </li>
            ))}
        </ul>
        <MyDropzone />
      </dd>
    </div>
  );
}

export default DocumentsBlock;
