/* eslint  @typescript-eslint/no-explicit-any: 0 */

export function fillFromParams<T>(
  state: T,
  queryParams: URLSearchParams,
  types: { isNumeric?: string[]; isDate?: string[]; isArray?: string[] },
) {
  const values: any = { ...state };

  queryParams.forEach((_, key) => {
    if (values[key]) {
      return;
    }

    values[key] = [];

    for (const value of queryParams.getAll(key)) {
      let targetValue: any = value;

      if (types.isDate?.indexOf(key) !== -1) {
        targetValue = new Date(Date.parse(value));
      }

      if ((value !== '' && !Number.isNaN(Number(value))) || types.isNumeric?.indexOf(key) !== -1) {
        targetValue = parseInt(value, 10);
      }

      values[key].push(targetValue);
    }

    if (types.isArray?.indexOf(key) === -1 && values[key].length) {
      // eslint-disable-next-line prefer-destructuring
      values[key] = values[key][0];
    }
  });

  return values;
}
