import { useEffect, useMemo, useRef } from 'react';

/* eslint  @typescript-eslint/no-explicit-any: 0 */

function debounce(fn: (...args: any[]) => void, time: number) {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  function wrapper(...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }

  return wrapper;
}

export const useDebounce = (callback: (...args: any[]) => void, time: number) => {
  const ref = useRef<(...args: any[]) => void>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = (...args: any[]) => {
      ref.current?.(...args);
    };

    return debounce(func, time);
  }, [time]);
};
