import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

import StyledLabel from './styled-label';

interface ComponentProps {
  children: React.ReactNode;
  title: string;
  error: string | undefined;
}

function LabelWithError({ children, title, error }: ComponentProps) {
  return (
    <>
      <StyledLabel>{title}</StyledLabel>
      <div className="relative rounded-md shadow-sm">
        {children}
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {error}
        </p>
      )}
    </>
  );
}

export default LabelWithError;
