import { SubmitHandler, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import StyledLabel from '../../../components/common/form/styled-label';
import StyledInput from '../../../components/common/form/styled-input';
import CommonButtonDefault from '../../../components/common/buttons/default';
import * as companiesService from '../../../services/api/companies';
import { useAuth } from '../../../hooks/use-auth';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import CommonAnimatedSpinner from '../../../components/common/animated/spinner';
import { strings } from '../../../localization/strings';

type Inputs = {
  name: string;
};

interface ComponentProps {
  onCloseForm: (data: boolean) => void;
}

export default function CompanyCreateForm({ onCloseForm }: ComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const { token } = useAuth();
  const { register, handleSubmit } = useForm<Inputs>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsLoading(true);

      const res = await companiesService.create(token, data);

      if (res) {
        setIsLoading(false);
        setIsSubmitSuccessful(true);

        setTimeout(() => {
          setIsSubmitSuccessful(false);

          navigate(`/companies/view/${res?.id}`);
        }, 2000);
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="py-2 text-xs">
          <CommonAnimatedSpinner sm />
        </div>
      )}
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <StyledLabel>Įmonės pavadinimas</StyledLabel>
          <StyledInput
            {...register('name', {
              required: 'Įmonės pavadinimas privalomas',
            })}
          />
        </div>

        {error && <AlertPrimary type="danger" text={error} />}
        {isSubmitSuccessful && (
          <div className="mb-2">
            <AlertPrimary size="xs" type="success" text="Įmonė sėkmingai sukurta" />
          </div>
        )}
        <div className="py-3 flex justify-end space-x-4">
          <CommonButtonDefault type="button" secondary onClick={() => onCloseForm(true)}>
            {strings.button.cancel}
          </CommonButtonDefault>
          <CommonButtonDefault primary type="submit">
            {strings.button.save}
          </CommonButtonDefault>
        </div>
      </form>
    </>
  );
}

// CompanyForm.defaultProps = {
//   showFormData: false,
// };
