import React, { useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import Select from 'react-select';
import classNames from 'classnames';

import CommonButtonDefault from '../../../components/common/buttons/default';
import { useCompany } from '../../../hooks/use-company';
import StyledSwitch from '../../../components/common/form/styled-switch';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import StyledError from '../../../components/common/form/styled-error';
import { strings } from '../../../localization/strings';
import StyledInput from '../../../components/common/form/styled-input';
import StyledLabel from '../../../components/common/form/styled-label';
import { Language } from '../../../entities/company-admin-structure';
import { Category } from '../../../entities/category';
import ExportNewsForm from '../../../entities/form/export-news';
import * as companiesService from '../../../services/api/companies';
import { useAuth } from '../../../hooks/use-auth';
import CompaniesReexportNewsModal from './export-reexport-modal';

const exportNewsTypes = [
  { value: 1, label: 'FTP' },
  { value: 2, label: 'El. paštas' },
];

const exportNewsFormats = [
  { value: 1, label: 'XML (UTF-8)' },
  { value: 2, label: 'HTML (UTF-8)' },
  { value: 3, label: 'PLAIN TEXT' },
  { value: 5, label: 'PLAIN TEXT ATTACHMENT (Sena koduotė)' },
];

const exportNewsFormatsFTP = [
  { value: 1, label: 'XML (UTF-8)' },
  { value: 4, label: 'PLAIN TEXT (UTF-8)' },
];

const exportNewsEncoding = [
  { value: 1, label: 'UTF-8' },
  { value: 2, label: 'Sena koduotė' },
];

const countryOptions = [
  { value: 1, label: 'Lietuvos' },
  { value: 2, label: 'Užsienio' },
];

function ExportNews() {
  const { token } = useAuth();
  const { companyDetails: company, updateCompany, companyStructure: structure } = useCompany();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<ExportNewsForm>({
    defaultValues: {
      export_news: company.export_news,
      export_type: company.export_type,
      export_news_format: company.export_news_format,
      export_news_encoding: company.export_news_encoding,
      export_news_ftp_port: company.export_news_ftp_port,
      export_news_ftp_username: company.export_news_ftp_username,
      export_news_ftp_password: company.export_news_ftp_password,
      export_news_ftp_address: company.export_news_ftp_address,
      export_news_categories: company.export_news_categories,
    },
  });
  const {
    fields: exportCategories,
    append: appendExportCategory,
    remove: removeExportCategory,
  } = useFieldArray({ control, name: 'export_news_categories' });

  const onSubmit: SubmitHandler<ExportNewsForm> = async (data) => {
    try {
      setIsLoading(true);
      setError('');

      const res = await companiesService.update(token, company?.id, data);

      if (res) {
        setIsLoading(false);
        setIsSubmitSuccessful(true);

        updateCompany(data);

        setTimeout(() => {
          setIsSubmitSuccessful(false);
        }, 2000);
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  const getAllCategories = (languageId: number) => {
    if (!structure.languages[languageId]) {
      return [];
    }

    return [...structure.languages[languageId].categories, ...structure.languages[languageId].guideCategories];
  };

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <>
      {error && (
        <div className="max-w-7xl mx-auto my-5">
          <AlertPrimary type="danger" text={error} />
        </div>
      )}

      {isSubmitSuccessful && (
        <div className="mb-2 mt-4">
          <AlertPrimary size="xs" type="success" text="Sėkmingai atnaujinta" />
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white sm:rounded-lg ring-1 ring-gray-900/5">
          <div className="p-4 font-medium leading-6 bg-gray-50 text-gray-900 flex flex-row sm:rounded-t-lg">
            <h3>Naujienų eksportavimas</h3>
            {watch('export_news') === 1 && (
              <div className="ml-auto">
                <CompaniesReexportNewsModal />
              </div>
            )}
            <div className={classNames(watch('export_news') ? 'ml-4' : 'ml-auto')}>
              <CommonButtonDefault type="submit" xs primary>
                Išsaugoti
              </CommonButtonDefault>
            </div>
            <div className="ml-4">
              <StyledSwitch name="export_news" control={control} />
            </div>
          </div>

          {watch('export_news') === 1 && (
            <div>
              <div className="grid grid-cols-4 gap-4 p-4">
                <div className="col-span-2">
                  <StyledLabel htmlFor="export_type">Tipas</StyledLabel>
                  <div className="mt-1">
                    <Controller
                      control={control}
                      name="export_type"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          options={exportNewsTypes}
                          placeholder="Pasirinkite tipą"
                          value={exportNewsTypes.find((option) => option.value === field.value)}
                          onChange={(e) => {
                            field.onChange(e?.value);
                          }}
                        />
                      )}
                    />
                    {errors.export_type && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
                  </div>
                </div>
                <div className="col-span-2">
                  <StyledLabel htmlFor="export_news_format">Formatas</StyledLabel>
                  <div className="mt-1">
                    {watch('export_type') === 2 ? (
                      <Controller
                        control={control}
                        name="export_news_format"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            options={exportNewsFormats}
                            placeholder="Pasirinkite siunčiamos informacijos formatą"
                            value={exportNewsFormats.find((option) => option.value === field.value)}
                            onChange={(e) => {
                              field.onChange(e?.value);
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Controller
                        control={control}
                        name="export_news_format"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            options={exportNewsFormatsFTP}
                            placeholder="Pasirinkite siunčiamos informacijos formatą"
                            value={exportNewsFormatsFTP.find((option) => option.value === field.value)}
                            onChange={(e) => {
                              field.onChange(e?.value);
                            }}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
                {watch('export_news_format') === 3 && (
                  <div className="col-span-2">
                    <StyledLabel>Koduotė</StyledLabel>

                    <Controller
                      control={control}
                      name="export_news_encoding"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          options={exportNewsEncoding}
                          placeholder="Pasirinkite siunčiamos informacijos koduotę"
                          value={exportNewsEncoding.find((option) => option.value === field.value)}
                          onChange={(e) => {
                            field.onChange(e?.value);
                          }}
                        />
                      )}
                    />
                    {errors.export_news_encoding && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
                  </div>
                )}
                {watch('export_type') === 1 && (
                  <>
                    <div className="col-span-1 mt-2">
                      <StyledLabel htmlFor="export_news_ftp_address">Serverio adresas</StyledLabel>

                      <div className="mt-1">
                        <StyledInput
                          id="export_news_ftp_address"
                          className={` ${errors.export_news_ftp_address && `!border-red-500`} `}
                          type="text"
                          {...register('export_news_ftp_address')}
                        />
                      </div>
                    </div>
                    <div className="col-span-1 mt-2">
                      <StyledLabel htmlFor="export_news_ftp_port">Serverio prievadas</StyledLabel>
                      <div className="mt-1">
                        <StyledInput
                          id="export_news_ftp_port"
                          className={` ${errors.export_news_ftp_port && `!border-red-500`} `}
                          type="text"
                          {...register('export_news_ftp_port')}
                        />
                      </div>
                    </div>
                    <div className="col-span-1 mt-2">
                      <StyledLabel htmlFor="export_news_ftp_username">Serverio vartotojo vardas</StyledLabel>
                      <div className="mt-1">
                        <StyledInput
                          id="export_news_ftp_username"
                          className={` ${errors.export_news_ftp_username && `!border-red-500`} `}
                          type="text"
                          {...register('export_news_ftp_username')}
                        />
                      </div>
                    </div>
                    <div className="col-span-1 mt-2">
                      <StyledLabel htmlFor="export_news_ftp_password">Serverio slaptažodis</StyledLabel>
                      <div className="mt-1">
                        <StyledInput
                          id="export_news_ftp_password"
                          className={` ${errors.export_news_ftp_username && `!border-red-500`} `}
                          type="text"
                          {...register('export_news_ftp_password')}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="border-t border-gray-900/5">
                <h3 className="p-4 font-medium">Eksportuojamas turinys</h3>
                {exportCategories.map((item, index) => (
                  <div key={`fields-export-${item.id}`} className="flex flex-row pb-2 px-4">
                    <div
                      className={classNames(
                        'grid gap-4 grow',
                        watch('export_type') === 2 ? 'grid-cols-4' : 'grid-cols-3',
                      )}
                    >
                      <div className="col-span-1">
                        <Controller
                          defaultValue={0}
                          control={control}
                          name={`export_news_categories.${index}.lang`}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              options={structure?.languages}
                              getOptionValue={(option: Language) => `${option.id}`}
                              getOptionLabel={(option: Language) => `${option.name}`}
                              placeholder="Pasirinkite kalbą"
                              value={structure?.languages.find((option) => option.id === field.value)}
                              onChange={(e) => {
                                field.onChange(e?.id);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-span-1">
                        <Controller
                          control={control}
                          name={`export_news_categories.${index}.country`}
                          render={({ field }) => (
                            <Select
                              options={countryOptions}
                              placeholder="Pasirinkite šalį"
                              isClearable
                              value={countryOptions.find((option) => option.value === field.value)}
                              onChange={(e) => {
                                field.onChange(e?.value);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-span-1">
                        <Controller
                          control={control}
                          name={`export_news_categories.${index}.category_id`}
                          render={({ field }) => (
                            <Select
                              options={getAllCategories(watch(`export_news_categories.${index}.lang`))}
                              placeholder="Pasirinkite temą"
                              isClearable
                              getOptionValue={(option: Category) => `${option.id}`}
                              getOptionLabel={(option: Category) => `${option.name}`}
                              value={getAllCategories(watch(`export_news_categories.${index}.lang`)).find(
                                (option) => option.id === field.value,
                              )}
                              onChange={(e) => {
                                field.onChange(e?.id);
                              }}
                            />
                          )}
                        />
                      </div>
                      {watch('export_type') === 2 && (
                        <div className="col-span-1">
                          <StyledInput
                            id={`export_news_categories.${index}.email`}
                            type="text"
                            noValidation
                            placeholder="El.pašto adresai, atskirti kableliu"
                            {...register(`export_news_categories.${index}.email`)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="pl-4">
                      <CommonButtonDefault
                        type="button"
                        onClick={() => removeExportCategory(index)}
                        sm
                        danger
                        className="ml-auto"
                      >
                        Ištrinti
                      </CommonButtonDefault>
                    </div>
                  </div>
                ))}
                <div className="p-4 pt-0 w-full">
                  <CommonButtonDefault
                    sm
                    onClick={() => appendExportCategory({ lang: 0, type: 0, email: '', category_id: undefined })}
                    type="button"
                    secondary
                  >
                    Pridėti
                  </CommonButtonDefault>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
}

export default ExportNews;
