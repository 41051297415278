import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

interface ComponentProps {
  children: ReactNode;
  column: string;
  current: string;
  onUpdate: (sort: string) => void;
}

export default function TableHeaderSortable({ column, current, onUpdate, children }: ComponentProps) {
  const isSorted = (c: string) => {
    if (current.substring(0, 1) === '-') {
      return current.substring(1) === c;
    }

    return current === c;
  };

  const isAscending = (c: string) => isSorted(c) && current.indexOf('-') === -1;

  const onChangeSort = (c: string) => {
    onUpdate(isAscending(c) ? `-${c}` : c);
  };

  return (
    <button
      type="button"
      onClick={() => onChangeSort(column)}
      className="group inline-flex no-underline text-gray-500 text-left text-xs font-medium uppercase tracking-wider"
    >
      {children}
      <span
        className={classNames(
          'ml-2 flex-none rounded',
          isSorted(column)
            ? 'bg-gray-100 text-gray-900 group-hover:bg-gray-200'
            : 'invisible group-hover:visible group-focus:visible text-gray-400',
        )}
      >
        {isAscending(column) ? (
          <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
        ) : (
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </span>
    </button>
  );
}
