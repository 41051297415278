import axios, { AxiosError } from 'axios';

import { parameters } from '../../constants/parameters';
import { Company } from '../../entities/company';
import { Pagination } from '../../entities/pagination';
import * as genericApi from './generic-api';
import { fromJsDate } from '../formatter/date';
import { Nullable } from '../../entities/nullable';

export const list = async (
  token: string,
  paramString?: object,
): Promise<{ items: Company[]; pagination: Pagination }> => {
  const url = `${parameters.api.baseUrl}/companies-crud`;

  return genericApi.list<Company>(token, url, paramString);
};

export const one = async (token: string, id: string): Promise<Company> => {
  const url = `${parameters.api.baseUrl}/companies-crud/${id}?${[
    'with[]=users',
    'with[]=documents',
    'with[]=exportNewsCategories',
  ].join('&')}`;

  return genericApi.one<Company>(token, url);
};

export const update = async (
  token: string,
  id: number,
  data: Partial<Nullable<Company>>,
): Promise<Company | undefined> => {
  const url = `${parameters.api.baseUrl}/companies-crud/${id}`;

  return genericApi.update<Company, Company>(token, url, data);
};

export const create = async (token: string, data: Partial<Company>): Promise<Company | undefined> => {
  const url = `${parameters.api.baseUrl}/companies-crud`;

  return genericApi.create<Company, Company>(token, url, data);
};

export const destroy = async (token: string, id: number): Promise<Company> => {
  const url = `${parameters.api.baseUrl}/companies-crud/${id}`;

  const res = await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const reexport = async (token: string, id: number, from: Date, to: Date) => {
  try {
    const url = `${parameters.api.baseUrl}/companies/${id}/reexport?export_dates=${fromJsDate(from)}%20-%20${fromJsDate(
      to,
    )}`;

    const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

    return res.data;
  } catch (e) {
    const err = e as AxiosError<{ message: string; errors: { [key: string]: string[] } }>;

    if (err.response) {
      throw new Error(err.response.data.message);
    }
  }

  return undefined;
};
