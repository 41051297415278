import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

import { Pagination } from '../../entities/pagination';
import { strings } from '../../localization/strings';

export default function PaginationComponent({
  pagination,
  onChangePage,
}: {
  pagination: Pagination;
  onChangePage: (pageNo: number) => void;
}) {
  return (
    <div className="flex items-center justify-between border-b border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          type="button"
          disabled={pagination.current <= 0}
          onClick={() => onChangePage(pagination.current - 1)}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          {strings.common.pagination.previous}
        </button>
        <button
          type="button"
          disabled={pagination.current >= pagination.pages - 1}
          onClick={() => onChangePage(pagination.current + 1)}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          {strings.common.pagination.next}
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {strings.common.pagination.showing} {strings.common.pagination.from}{' '}
            <span className="font-medium">{pagination.current * pagination.size + 1}</span>{' '}
            {strings.common.pagination.to}{' '}
            <span className="font-medium">
              {Math.min(pagination.current * pagination.size + pagination.size, pagination.total)}
            </span>{' '}
            {strings.common.pagination.of} <span className="font-medium">{pagination.total}</span>{' '}
            {strings.common.pagination.items}
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              type="button"
              disabled={pagination.current <= 0}
              onClick={() => onChangePage(pagination.current - 1)}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">{strings.common.pagination.previous}</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {Array.from(Array(pagination.pages), (e, i) => (
              <button
                type="button"
                aria-current="page"
                onClick={() => onChangePage(i)}
                key={`page-${i}`}
                className={classNames(
                  'relative inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20',
                  i === pagination.current
                    ? 'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0',
                )}
              >
                {i + 1}
              </button>
            ))}
            <button
              type="button"
              disabled={pagination.current >= pagination.pages - 1}
              onClick={() => onChangePage(pagination.current + 1)}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">{strings.common.pagination.next}</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
