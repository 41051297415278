import React, { createContext, useState, useEffect, useCallback, useMemo, PropsWithChildren } from 'react';
import Cookies from 'universal-cookie';

import { current, tokenFromSession } from '../services/api/users';
import { AuthToken } from '../entities/auth-token';
import { NoExtraProps } from '../entities/no-extra-props';
import { Administrator } from '../entities/administrator';
import { parameters } from '../constants/parameters';

const COOKIE_NAME_TOKEN = 'admin_token';
const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
const cookies = new Cookies();

interface ContextValue {
  token: string;
  loading: boolean;
  user?: Administrator;
  setUserToken: (authToken: AuthToken) => void;
  signout: () => void;
}

const AuthContext = createContext<ContextValue>({
  token: '',
  loading: true,
  setUserToken: () => null,
  signout: () => null,
});

function AuthContextProvider({ children }: PropsWithChildren<NoExtraProps>) {
  const [token, setToken] = useState<string>(cookies.get(COOKIE_NAME_TOKEN));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Administrator>();

  const signout = useCallback(() => {
    document.location.href = `${parameters.websiteUrl}/lt/logout`;
  }, []);

  const setUserToken = useCallback(
    (authToken: AuthToken) => {
      cookies.set(COOKIE_NAME_TOKEN, authToken.accessToken, {
        expires: authToken.expiredAt,
        domain: COOKIE_DOMAIN || undefined,
        path: '/',
      });

      setToken(authToken.accessToken);
      setIsLoading(true);
    },
    [setToken],
  );

  useEffect(() => {
    const fetchUser = async (currToken: string) => {
      try {
        const currUser = await current(currToken);

        setUser(currUser);
        setIsLoading(false);
      } catch (e) {
        setUser(undefined);
        setIsLoading(false);
      }
    };

    const fetchToken = async () => {
      try {
        const tokenResponse = await tokenFromSession();

        setUserToken(tokenResponse);
        await fetchUser(tokenResponse.accessToken);
      } catch (e) {
        setIsLoading(false);
      }
    };

    if (!token) {
      fetchToken();
    } else if (!user && token) {
      fetchUser(token);
    } else {
      setIsLoading(false);
    }
  }, [token, setUserToken, user]);

  const contextValue = useMemo(
    () => ({
      token,
      signout,
      user,
      setUserToken,
      loading: isLoading,
    }),
    [token, user, signout, setUserToken, isLoading],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthContextProvider };
