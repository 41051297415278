import React, { forwardRef } from 'react';
import tw, { styled } from 'twin.macro';

interface ComponentProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  disabled?: boolean;
  rows?: number;
  fullWidth?: boolean;
}

const Textarea = styled.textarea<ComponentProps>`
  ${tw`appearance-none block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`};
  ${(props) => (props.fullWidth ? tw`w-full` : tw`w-3/4`)}
  ${(props) => (props.disabled ? tw`bg-gray-300 text-gray-700` : '')}
`;

const StyledTextarea = forwardRef<HTMLTextAreaElement, ComponentProps>(({ rows, ...props }, ref) => {
  return <Textarea rows={rows} ref={ref} {...props} />;
});

StyledTextarea.displayName = 'StyledTextarea';

StyledTextarea.defaultProps = {
  disabled: false,
  rows: 3,
  fullWidth: true,
};

export default StyledTextarea;
