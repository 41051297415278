import React from 'react';
import tw, { styled } from 'twin.macro';

import CommonAnimatedSpinner from '../animated/spinner';

interface ComponentProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  danger?: boolean;
  link?: boolean;
  disabled?: boolean;
  secondary?: boolean;
  xs?: boolean;
  sm?: boolean;
  editLink?: boolean;
  transparent?: boolean;
  isLoading?: boolean;
}

const Button = styled.button<ComponentProps>`
  ${tw`block text-base rounded py-2 px-4 font-medium`}
  ${tw`bg-gray-300 text-gray-900 hover:bg-gray-400`}
  ${(props) => props.primary && tw`bg-blue-800 text-white hover:bg-blue-900`}
  ${(props) => props.danger && tw`bg-red-500 text-white hover:bg-red-800`}
  ${(props) => props.disabled && tw`opacity-25`}
  ${(props) =>
    props.secondary &&
    tw`bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
  ${(props) => props.xs && tw`px-2 py-1 text-xs`}
  ${(props) => props.sm && tw`px-3 py-1.5 text-sm`}
   ${(props) =>
    props.link &&
    tw`!p-0 text-gray-900 inline-block leading-6 bg-transparent hover:bg-transparent underline hover:text-blue-500 font-normal rounded-none`}
  ${(props) =>
    props.editLink &&
    tw`!p-0 text-indigo-600 inline-block text-sm bg-transparent hover:bg-transparent font-medium text-indigo-600 hover:text-indigo-500 rounded-none`}
  ${(props) => props.transparent && tw`bg-transparent hover:bg-transparent rounded-none`}
`;

function CommonButtonDefault({ children, isLoading, ...rest }: ComponentProps) {
  return (
    <Button {...rest}>
      {isLoading ? (
        <div className="flex">
          <div>{children}</div>
          <div className="ml-2">
            <CommonAnimatedSpinner xs light />
          </div>
        </div>
      ) : (
        children
      )}
    </Button>
  );
}

CommonButtonDefault.defaultProps = {
  primary: false,
  danger: false,
  disabled: false,
  link: false,
  secondary: false,
  xs: false,
  sm: false,
  editLink: false,
  transparent: false,
  isLoading: false,
};

export default CommonButtonDefault;
