import axios, { AxiosError } from 'axios';

import { AuthToken } from '../../entities/auth-token';
import { parameters } from '../../constants/parameters';
import { Administrator } from '../../entities/administrator';
import * as genericApi from './generic-api';
import { User } from '../../entities/user';
import UserForm from '../../entities/form/user';
import ValidationError from '../../entities/validation-error';

export const login = async (email: string, password: string): Promise<AuthToken> => {
  const url = `${parameters.api.baseUrl}/users/admin/login`;

  const result = await axios.post(url, {
    email,
    password,
  });

  return {
    accessToken: result.data.accessToken,
    refreshToken: result.data.refreshToken,
    expiredAt: new Date(result.data.expiredAt),
  };
};

export const current = async (token: string): Promise<Administrator> => {
  const url = `${parameters.api.baseUrl}/users/current`;

  const res = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const tokenFromSession = async (): Promise<AuthToken> => {
  const url = `${parameters.api.baseUrl}/users/from-session`;

  const res = await axios.get(url, {
    withCredentials: true,
  });

  return {
    ...res.data,
    expiredAt: new Date(res.data.expiredAt),
  };
};

export const one = async (token: string, id: string): Promise<User> => {
  const url = `${parameters.api.baseUrl}/users-crud/${id}?with[]=company`;

  return genericApi.one<User>(token, url);
};

export const update = async (token: string, id: number, data: Partial<UserForm>): Promise<User | undefined> => {
  const url = `${parameters.api.baseUrl}/users-crud/${id}`;

  return genericApi.update<UserForm, User>(token, url, data);
};

export const remove = async (token: string, id: number): Promise<{ status: string }> => {
  const url = `${parameters.api.baseUrl}/users-crud/${id}`;

  const res = await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });

  return res.data;
};

export const create = async (token: string, data: Partial<UserForm>): Promise<User | undefined> => {
  const url = `${parameters.api.baseUrl}/users-crud`;

  try {
    const res = await axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });

    return res.data;
  } catch (e) {
    const err = e as AxiosError<{ message: string; errors: { [key: string]: string[] } }>;

    if (err.response) {
      throw new ValidationError({
        message: err.response.data.message,
        errors: Object.entries(err.response.data.errors).map(([key, value]) => {
          return {
            key,
            value: value[0],
          };
        }),
      });
    }
  }

  return undefined;
};
