import axios, { AxiosError } from 'axios';

import { parameters } from '../../constants/parameters';
import * as genericApi from './generic-api';
import { Comment } from '../../entities/comment';

export const companyComments = async (token: string, id: number) => {
  const url = `${parameters.api.baseUrl}/companies-comments?company_id=${id}&sort[]=-pinned&sort[]=-created_at`;

  return genericApi.list<Comment>(token, url);
};

export const createComment = async (token: string, id: number, data: Partial<Comment>): Promise<Comment> => {
  const url = `${parameters.api.baseUrl}/companies-comments`;

  try {
    const result = await axios.post(
      url,
      {
        ...data,
        company_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.message);
    // }
  }
};

export const editComment = async (token: string, id: number, data: Partial<Comment>): Promise<Comment> => {
  const url = `${parameters.api.baseUrl}/companies-comments/${id}`;

  try {
    const result = await axios.put(
      url,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.message);
    // }
  }
};

export const deleteComment = async (token: string, id: number): Promise<string> => {
  const url = `${parameters.api.baseUrl}/companies-comments/${id}`;

  try {
    const result = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    // if (e typeof AxiosError) {
    throw new Error(err.message);
    // }
  }
};
