import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import { useAuth } from '../../../hooks/use-auth';
import ModalContent from '../../../components/modals/content';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import CommonButtonDefault from '../../../components/common/buttons/default';
import * as companiesService from '../../../services/api/companies';
import { useCompany } from '../../../hooks/use-company';
import AlertPrimary from '../../../components/common/alerts/alert-primary';

interface DateRangeForm {
  from: Date;
  to: Date;
}

function CompaniesReexportNewsModal() {
  const { token } = useAuth();
  const { companyDetails } = useCompany();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string>();
  const { control, handleSubmit } = useForm<DateRangeForm>();

  const onDisplayModal = () => {
    setIsModalVisible(true);
  };

  const onSubmit: SubmitHandler<DateRangeForm> = async (data) => {
    setIsLoading(true);
    setError('');
    setSuccess('');

    if (companyDetails.id) {
      try {
        const res = await companiesService.reexport(token, companyDetails.id, data.from, data.to);

        setSuccess(res.message);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError((e as Error).message);
      }
    }
  };

  return (
    <div>
      <CommonButtonDefault type="button" secondary xs onClick={() => onDisplayModal()}>
        Pakartoti eksportą
      </CommonButtonDefault>
      {isModalVisible && (
        <ModalContent visible onCloseClick={() => setIsModalVisible(false)} title="Pakartotinis eksportas">
          <div>
            {error && (
              <div className="mb-2">
                <AlertPrimary type="danger" size="xs" text={error} />
              </div>
            )}

            {success && (
              <div className="mb-2">
                <AlertPrimary size="xs" type="success" text={success} />
              </div>
            )}

            <div className="flex">
              <div className="">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Laikotarpis nuo
                </label>
                <div className="mt-1">
                  <Controller
                    control={control}
                    name="from"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        // monthsShown={2}
                        showYearDropdown
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        selected={field.value ? new Date(field.value) : undefined}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        disabledKeyboardNavigation
                      />
                    )}
                  />
                </div>
              </div>
              <div className="pl-4">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Laikotarpis iki
                </label>
                <div className="mt-1">
                  <Controller
                    control={control}
                    name="to"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        // monthsShown={2}
                        showYearDropdown
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        selected={field.value ? new Date(field.value) : undefined}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        disabledKeyboardNavigation
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex">
                <CommonButtonDefault primary type="button" onClick={handleSubmit(onSubmit)}>
                  Eksportuoti
                </CommonButtonDefault>
                {isLoading && (
                  <div className="mt-2 pl-3">
                    <CommonAnimatedLoader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalContent>
      )}
    </div>
  );
}

export default CompaniesReexportNewsModal;
