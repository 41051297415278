import axios, { AxiosError } from 'axios';

import { parameters } from '../../constants/parameters';
import { CompanyDocument } from '../../entities/company';

export const uploadFile = async (token: string, id: number, data: File, type: string): Promise<CompanyDocument> => {
  const url = `${parameters.api.baseUrl}/companies-documents/upload/${id}`;

  const formData = new FormData();

  formData.append('file', data);
  formData.append('type', type);

  try {
    const result = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return result.data;
  } catch (e) {
    const err = e as AxiosError;

    throw new Error(err.message);
  }
};
