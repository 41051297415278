import React, { useState } from 'react';
import classNames from 'classnames';

import CompanyForm from './company-form';
import { useCompany } from '../../../hooks/use-company';
import CommonButtonDefault from '../../../components/common/buttons/default';

export default function CompanyInfoBlock() {
  const { companyDetails } = useCompany();
  const [showForm, setShowForm] = useState<boolean>(false);

  const onHideForm = () => {
    setShowForm(false);
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg ring-1 ring-gray-900/5">
      <div className="flex p-4 bg-gray-50">
        <h3 className="mr-auto font-medium leading-6 text-gray-900">Įmonės informacija</h3>
        <CommonButtonDefault onClick={() => setShowForm(!showForm)} editLink>
          {!showForm ? 'Keisti' : 'Uždaryti'}
        </CommonButtonDefault>
      </div>
      <div className="border-t border-gray-100">
        {showForm && <CompanyForm onCloseForm={onHideForm} />}
        {!showForm && (
          <dl className="divide-y divide-gray-100">
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-900">Aktyvi</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <span
                  className={classNames(
                    companyDetails?.is_active
                      ? 'text-green-700 bg-green-50 ring-green-600/20'
                      : 'text-red-600 bg-red-50 ring-red-500/10',
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset inline-block',
                  )}
                >
                  {companyDetails?.is_active ? 'Taip' : 'Ne'}
                </span>
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-900">Demo</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {companyDetails?.is_demo ? (
                  <span
                    className={classNames(
                      'text-red-600 bg-red-50 ring-red-500/10',
                      'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset inline-block',
                    )}
                  >
                    Taip
                  </span>
                ) : (
                  'Ne'
                )}
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-900">Įmonės kodas</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {companyDetails?.registration_number || '-'}
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-900">PVM mokėtojo kodas</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {companyDetails?.vat_number || '-'}
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-900">Adresas</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {companyDetails?.address || '-'}
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-900">Telefonas</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {companyDetails?.phone || '-'}
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-900">El.paštas</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {companyDetails?.email || '-'}
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-900">El.paštas sąskaitoms</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {companyDetails?.email_invoices || '-'}
              </dd>
            </div>
            {companyDetails?.comments && (
              <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-900">Pastabos ir pageidavimais</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {companyDetails?.comments}
                </dd>
              </div>
            )}
          </dl>
        )}
      </div>
    </div>
  );
}
