import { useContext } from 'react';

import { CompanyContext } from '../context/company';

export const useCompany = () => {
  const context = useContext(CompanyContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useCompany was used outside of its Provider');
  }

  return context;
};
