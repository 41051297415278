import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import { FirstLetter } from '../../services/formatter/createAvatar';
import { getHumanDate, getMiliseconds } from '../../services/formatter/date';
import CommonButtonDefault from '../common/buttons/default';
import { Comment } from '../../entities/comment';
import { deleteComment, editComment } from '../../services/api/comments';
import { useAuth } from '../../hooks/use-auth';
import StyledError from '../common/form/styled-error';
import IMAGES from '../../assets/images';
import AlertPrimary from '../common/alerts/alert-primary';
import CommonAnimatedSpinner from '../common/animated/spinner';
import { useComments } from '../../hooks/use-comments';
import { strings } from '../../localization/strings';

interface ComponentProps {
  item: Comment;
}

export default function CommentComponent({ item }: ComponentProps) {
  const [showCommentToolbar, setShowCommentToolbar] = useState<boolean>(false);

  const { token, user: currentUser } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [isDeleteSuccessful, setIsDeleteSuccessful] = useState<boolean>(false);
  const { updateComment, removeComment } = useComments();

  type Inputs = {
    description: string;
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      description: item.description,
    },
  });

  const onEditComment: SubmitHandler<Inputs> = async (data) => {
    try {
      if (currentUser?.id) {
        setIsLoading(true);

        const formData: Partial<Comment> = { ...data, type: 'comment', user_id: currentUser.id };

        const res = await editComment(token, item.id, formData);

        if (res) {
          setIsLoading(false);
          setIsSubmitSuccessful(true);
          setShowForm(false);
          updateComment(item.id, formData);

          setTimeout(() => {
            setIsSubmitSuccessful(false);
          }, 2000);
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  const onPinComment = async (pinValue: number) => {
    setShowCommentToolbar(false);
    try {
      if (currentUser?.id) {
        setIsLoading(true);

        const formData: Partial<Comment> = {
          pinned: pinValue,
        };

        const res = await editComment(token, item.id, formData);

        if (res) {
          setIsLoading(false);
          updateComment(item.id, formData);
          reset();
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  const onDeleteComment = async () => {
    setShowCommentToolbar(false);

    try {
      if (currentUser?.id) {
        setIsLoading(true);

        const res = await deleteComment(token, item.id);

        if (res) {
          setIsLoading(false);
          removeComment(item);
          setIsDeleteSuccessful(true);

          setTimeout(() => {
            setIsDeleteSuccessful(false);
          }, 2000);
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  return (
    <>
      {isDeleteSuccessful && (
        <div className="mb-2">
          <AlertPrimary size="xs" type="success" text="Komentaras sėkmingai ištrintas" />
        </div>
      )}
      <div className="relative mt-3 h-6 w-6 flex rounded-full bg-primary text-white items-center justify-center text-xs font-semibold shrink-0">
        {FirstLetter(item.user_name)}
      </div>
      <div
        className={`flex-auto rounded-md p-3 ring-1 ring-inset relative ${
          item.pinned ? 'ring-amber-200 pt-4 bg-orange-50' : 'ring-gray-200'
        }`}
      >
        {item.pinned === 1 && (
          <CommonButtonDefault className="absolute top-0.5 left-0.5" xs transparent onClick={() => onPinComment(0)}>
            <img className="h-3 w-auto flex" src={IMAGES.ICONS.THUMBTACK} alt="Message is pinned" />
          </CommonButtonDefault>
        )}
        <div className="flex justify-between align-middle items-center gap-x-4 relative">
          <div className="py-0.5 text-xs leading-5 text-gray-500">
            <span className="font-medium text-gray-900">{item.user_name}</span> parašė komentarą
          </div>
          <div className="flex space-x-3 items-center">
            <time dateTime={item.updated_at} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
              {getHumanDate(getMiliseconds(item.updated_at))}
            </time>
            <div className="relative">
              <CommonButtonDefault xs transparent onClick={() => setShowCommentToolbar(!showCommentToolbar)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                  />
                </svg>
              </CommonButtonDefault>
              {showCommentToolbar && (
                <div className="rounded-md bg-white p-1 absolute right-0 top-[25px] ring-1 ring-inset ring-gray-300 z-10 divide-y w-36 px-2 shadow-md">
                  <div className="px-1 pb-1">
                    {item.pinned === 1 ? (
                      <CommonButtonDefault className="font-semibold" xs transparent onClick={() => onPinComment(0)}>
                        {strings.button.unpin}
                      </CommonButtonDefault>
                    ) : (
                      <CommonButtonDefault className="font-semibold" xs transparent onClick={() => onPinComment(1)}>
                        {strings.button.pin}
                      </CommonButtonDefault>
                    )}
                  </div>
                  {item.user_id === currentUser?.id && (
                    <>
                      <div className="px-1 pt-1">
                        <CommonButtonDefault
                          xs
                          transparent
                          className="font-semibold"
                          onClick={() => {
                            setShowForm(!showForm);
                            setShowCommentToolbar(false);
                          }}
                        >
                          {strings.button.edit}
                        </CommonButtonDefault>
                      </div>
                      <div className="px-1 pt-1">
                        <CommonButtonDefault className="font-semibold" xs transparent onClick={() => onDeleteComment()}>
                          {strings.button.delete}
                        </CommonButtonDefault>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* eslint-disable react/no-danger */}
        <p
          className={`text-sm leading-6 text-gray-500 whitespace-pre-wrap ${showForm ? 'hidden' : 'block'}`}
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
        {error && <AlertPrimary type="danger" text={error} />}
        {isSubmitSuccessful && (
          <div className="mb-2">
            <AlertPrimary size="xs" type="success" text="Komentaras sėkmingai atnaujintas" />
          </div>
        )}

        {isLoading && (
          <div className="py-2 text-xs">
            <CommonAnimatedSpinner sm />
          </div>
        )}

        <form onSubmit={handleSubmit(onEditComment)} className={`relative ${showForm ? 'flex-auto' : 'hidden'}`}>
          {errors.description && <StyledError>Komentaras privalomas</StyledError>}
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="description" className="sr-only">
              {strings.button.edit}
            </label>
            <TextareaAutosize
              id="description"
              className="w-full border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 resize-y"
              {...register('description', { required: true })}
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex space-x-3 py-2 pl-3 pr-2">
            <CommonButtonDefault primary sm type="submit">
              {strings.button.save}
            </CommonButtonDefault>
            <CommonButtonDefault type="button" secondary sm onClick={() => setShowForm(false)}>
              {strings.button.cancel}
            </CommonButtonDefault>
          </div>
        </form>
      </div>
    </>
  );
}
