import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { Control, Controller } from 'react-hook-form';
import React from 'react';

interface ComponentProps {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  control: Control<any>;
  name: string;
  required?: boolean;
  disabled?: boolean;
}

function StyledSwitch({ control, name, required, disabled }: ComponentProps) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field }) => (
        <Switch
          checked={!!field.value}
          disabled={disabled}
          onChange={(e) => {
            field.onChange(e ? 1 : 0);
          }}
          className={classNames(
            field.value ? 'bg-indigo-600' : 'bg-gray-200',
            disabled ? 'opacity-50' : '',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              field.value ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
      )}
    />
  );
}

StyledSwitch.defaultProps = {
  required: false,
  disabled: false,
};

export default StyledSwitch;
