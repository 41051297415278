import { SubmitHandler, useForm } from 'react-hook-form';
import React, { useState } from 'react';

import StyledLabel from '../../../components/common/form/styled-label';
import StyledInput from '../../../components/common/form/styled-input';
import CommonButtonDefault from '../../../components/common/buttons/default';
import * as companiesService from '../../../services/api/companies';
import { useAuth } from '../../../hooks/use-auth';
import AlertPrimary from '../../../components/common/alerts/alert-primary';
import CommonAnimatedSpinner from '../../../components/common/animated/spinner';
import StyledError from '../../../components/common/form/styled-error';
import { strings } from '../../../localization/strings';
import StyledTextarea from '../../../components/common/form/styled-textarea';
import { useCompany } from '../../../hooks/use-company';
import StyledSwitch from '../../../components/common/form/styled-switch';

type Inputs = {
  name: string;
  registration_number?: string;
  vat_number?: string;
  email: string;
  email_invoices: string;
  phone?: string;
  address?: string;
  comments?: string;
  is_active: number;
  is_demo: number;
  is_private_person: number;
  // is_string: number;
  // news_delay: number;
  // photos_monthly_limit: number;
  // photos_downloaded_this_month: number;
};

interface ComponentProps {
  onCloseForm: (data: boolean) => void;
}

export default function CompanyForm({ onCloseForm }: ComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const { token } = useAuth();
  const { updateCompany, companyDetails: company } = useCompany();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: company?.name,
      registration_number: company?.registration_number,
      vat_number: company?.vat_number,
      email: company?.email,
      email_invoices: company?.email_invoices,
      phone: company?.phone,
      address: company?.address,
      comments: company?.comments,
      is_active: company?.is_active,
      is_demo: company?.is_demo,
      is_private_person: company?.is_private_person,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (company?.id) {
        setIsLoading(true);

        // const formData: Partial<Comment> = { ...data, user_id: company.id };

        const res = await companiesService.update(token, company?.id, data);

        if (res) {
          setIsLoading(false);
          setIsSubmitSuccessful(true);

          updateCompany(data);

          setTimeout(() => {
            setIsSubmitSuccessful(false);
            onCloseForm(true);
          }, 2000);
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  return (
    <div className="p-3 border-gray-100 border-b">
      {isLoading && (
        <div className="py-2 text-xs">
          <CommonAnimatedSpinner sm />
        </div>
      )}
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex mt-4 gap-x-10">
          <div className="flex items-start">
            <div className="flex h-6 items-center">
              <StyledSwitch name="is_active" control={control} />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="is_active" className="font-medium text-gray-900">
                Aktyvi
              </label>
            </div>
          </div>
          <div className="flex items-start">
            <div className="flex h-6 items-center">
              <StyledSwitch name="is_demo" control={control} />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="is_demo" className="font-medium text-gray-900">
                Demo
              </label>
            </div>
          </div>
        </div>
        <div>
          <StyledLabel>Įmonės pavadinimas</StyledLabel>
          <StyledInput
            {...register('name', {
              required: true,
            })}
          />
          {errors.name && <StyledError>{strings.error.companyNamedIsRequired}</StyledError>}
        </div>
        <div>
          <StyledLabel>Įmonės kodas</StyledLabel>
          <StyledInput type="text" {...register('registration_number')} />
        </div>
        <div>
          <StyledLabel>PVM kodas</StyledLabel>
          <StyledInput type="text" {...register('vat_number')} />
        </div>
        <div>
          <StyledLabel>Adresas</StyledLabel>
          <StyledInput type="text" {...register('address')} />
        </div>
        <div>
          <StyledLabel>Telefono nr.</StyledLabel>
          <StyledInput {...register('phone')} />
        </div>
        <div>
          <StyledLabel>El.pašto adresas komunikacijai</StyledLabel>
          <StyledInput
            type="email"
            className={` ${errors.email && `!border-red-500`} `}
            {...register('email', {
              required: 'El.pašto adresas yra privalomas',
            })}
          />
          {errors.email && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
        </div>
        <div>
          <StyledLabel>El.pašto adresas sąskaitoms</StyledLabel>
          <StyledInput
            type="email"
            className={` ${errors.email && `!border-red-500`} `}
            {...register('email_invoices')}
          />
          {errors.email && <StyledError>{strings.error.fieldIsRequired}</StyledError>}
        </div>
        <div>
          <StyledLabel>Pastabos ir pageidavimai</StyledLabel>
          <StyledTextarea rows={4} className={` ${errors.comments && `!border-red-500`} `} {...register('comments')} />
        </div>
        {error && <AlertPrimary type="danger" text={error} />}
        {isSubmitSuccessful && (
          <div className="mb-2">
            <AlertPrimary size="xs" type="success" text="Įmonės informacija sėkmingai atnaujinta" />
          </div>
        )}
        <div className="py-3 flex justify-end space-x-4">
          <CommonButtonDefault type="button" secondary onClick={() => onCloseForm(true)}>
            {strings.button.cancel}
          </CommonButtonDefault>
          <CommonButtonDefault primary type="submit">
            {strings.button.save}
          </CommonButtonDefault>
        </div>
      </form>
    </div>
  );
}

// CompanyForm.defaultProps = {
//   showFormData: false,
// };
